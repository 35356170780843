import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import BASE_URL from '../../../config'
import useAuth from '../../../hooks/useAuth'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import Message from './Message'

export default function Navigation() {
  const { messageCount } = useSelector(state => state.app)
  const username = localStorage.getItem('username')
  const userRole = localStorage.getItem('userRole')
  const location = useLocation()

  const baseUrl = BASE_URL

  const { setAuth } = useAuth()

  const headers = {
    Authorization: 'Bearer ' + localStorage.getItem('authToken'),
  }

  function logout() {
    localStorage.clear()
    axios
      .post(baseUrl + '/logout', {}, { headers })
      .then(response => {
        setAuth({})
        toast.success('Logged out Successfully!')
      })
      .catch(function (error) {
        toast.error(error.response?.data?.message)
      })
  }

  return (
    <div className="col-xl-3 col-lg-3 col-md-12">
      <div className="dashboard__inner sticky-top">
        <div className="dashboard__nav__title">
          <h6>Welcome, {username}</h6>
        </div>
        <div className="dashboard__nav">
          <ul>
            {userRole !== 'coordinator' && (
              <li>
                <Link
                  className={
                    location.pathname.includes(userRole) ? 'active' : ''
                  }
                  to={'/dashboard/' + userRole}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-home"
                  >
                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                    <polyline points="9 22 9 12 15 12 15 22" />
                  </svg>
                  {userRole === 'student'
                    ? 'Academic Program overview'
                    : 'Dashboard'}
                </Link>
              </li>
            )}

            {userRole === 'student' && (
              <>
                <li>
                  <Link
                    className={
                      location.pathname.includes('course-catalog')
                        ? 'active'
                        : ''
                    }
                    to="/dashboard/course-catalog"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 7c0 2.21-4.03 4-9 4S3 9.21 3 7m18 0c0-2.21-4.03-4-9-4S3 4.79 3 7m18 0v5M3 7v5m18 0c0 2.21-4.03 4-9 4s-9-1.79-9-4m18 0v5c0 2.21-4.03 4-9 4s-9-1.79-9-4v-5"
                      />
                    </svg>
                    Course Catalog
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      location.pathname.includes('stud-progress')
                        ? 'active'
                        : ''
                    }
                    to="/dashboard/stud-progress"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 16v-5m4 5V8m4 8v-2m2-10H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"
                      />
                    </svg>
                    Student Progress Tracking
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      location.pathname.includes('exams') ? 'active' : ''
                    }
                    to="/dashboard/exams"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-book-open"
                    >
                      <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
                      <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
                    </svg>
                    Exams
                  </Link>
                </li>
              </>
            )}

            {userRole === 'coordinator' && (
              <>
                <li>
                  <Link
                    className={
                      location.pathname.includes('program-overview')
                        ? 'active'
                        : ''
                    }
                    to="/dashboard/program-overview"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
                      />
                    </svg>
                    Program overview and Alignment
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      location.pathname.includes(
                        'curriculum-development-updates'
                      )
                        ? 'active'
                        : ''
                    }
                    to="/dashboard/curriculum-development-updates"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        cx="6.18"
                        cy="17.82"
                        r="2.18"
                        fill="currentColor"
                      />
                      <path
                        fill="currentColor"
                        d="M4 4.44v2.83c7.03 0 12.73 5.7 12.73 12.73h2.83c0-8.59-6.97-15.56-15.56-15.56zm0 5.66v2.83c3.9 0 7.07 3.17 7.07 7.07h2.83c0-5.47-4.43-9.9-9.9-9.9z"
                      />
                    </svg>
                    Curriculum Development Updates
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      location.pathname.includes('program-evaluation')
                        ? 'active'
                        : ''
                    }
                    to="/dashboard/program-evaluation"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                      >
                        <path d="m16 44l8-4l8 4V24.944A11.955 11.955 0 0 1 24 28a11.955 11.955 0 0 1-8-3.056V44Z" />
                        <path d="M36 16a11.97 11.97 0 0 1-4 8.944A11.955 11.955 0 0 1 24 28a11.955 11.955 0 0 1-8-3.056A11.97 11.97 0 0 1 12 16c0-6.627 5.373-12 12-12s12 5.373 12 12Z" />
                        <path d="M24 21V11l-2 1m2 9h2m-2 0h-2" />
                      </g>
                    </svg>
                    Program Evalution
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      location.pathname.includes('support-and-enquiries')
                        ? 'active'
                        : ''
                    }
                    to="/dashboard/support-and-enquiries"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="currentColor"
                        d="M2.8 2.06A1.75 1.75 0 0 1 4.41 1h7.18c.7 0 1.333.417 1.61 1.06l2.74 6.395c.04.093.06.194.06.295v4.5A1.75 1.75 0 0 1 14.25 15H1.75A1.75 1.75 0 0 1 0 13.25v-4.5c0-.101.02-.202.06-.295Zm1.61.44a.25.25 0 0 0-.23.152L1.887 8H4.75a.75.75 0 0 1 .6.3L6.625 10h2.75l1.275-1.7a.75.75 0 0 1 .6-.3h2.863L11.82 2.652a.25.25 0 0 0-.23-.152Zm10.09 7h-2.875l-1.275 1.7a.75.75 0 0 1-.6.3h-3.5a.75.75 0 0 1-.6-.3L4.375 9.5H1.5v3.75c0 .138.112.25.25.25h12.5a.25.25 0 0 0 .25-.25Z"
                      />
                    </svg>
                    Support & Enquiries
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      location.pathname.includes('reports') ? 'active' : ''
                    }
                    to="/dashboard/reports"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="currentColor"
                        d="M3.76 17.01h12.48A7.991 7.991 0 0 0 18 12c0-4.41-3.58-8-8-8s-8 3.59-8 8c0 1.9.66 3.63 1.76 5.01zM9 6c0-.55.45-1 1-1s1 .45 1 1c0 .56-.45 1-1 1s-1-.44-1-1zM4 8c0-.55.45-1 1-1s1 .45 1 1c0 .56-.45 1-1 1s-1-.44-1-1zm4.52 3.4c.84-.83 6.51-3.5 6.51-3.5s-2.66 5.68-3.49 6.51c-.84.84-2.18.84-3.02 0a2.13 2.13 0 0 1 0-3.01zM3 13c0-.55.45-1 1-1s1 .45 1 1c0 .56-.45 1-1 1s-1-.44-1-1zm6 0c0-.55.45-1 1-1s1 .45 1 1c0 .56-.45 1-1 1s-1-.44-1-1zm6 0c0-.55.45-1 1-1s1 .45 1 1c0 .56-.45 1-1 1s-1-.44-1-1z"
                      />
                    </svg>
                    Reports
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="dashboard__nav__title mt-40">
          <h6>user</h6>
        </div>
        <div className="d-none">
          <Message />
        </div>
        <div className="dashboard__nav">
          <ul>
            <li>
              <Link
                className={
                  location.pathname.includes('profile') ? 'active' : ''
                }
                to="/dashboard/profile"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-user"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                  <circle cx={12} cy={7} r={4} />
                </svg>
                My Profile
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname.includes('message') ? 'active' : ''
                }
                to="/dashboard/message"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M6.5 13.5h7v-1h-7v1Zm0-3h11v-1h-11v1Zm0-3h11v-1h-11v1ZM3 20.077V4.615q0-.69.463-1.152Q3.925 3 4.615 3h14.77q.69 0 1.152.463q.463.462.463 1.152v10.77q0 .69-.462 1.153q-.463.462-1.153.462H6.077L3 20.077ZM5.65 16h13.735q.23 0 .423-.192q.192-.193.192-.423V4.615q0-.23-.192-.423Q19.615 4 19.385 4H4.615q-.23 0-.423.192Q4 4.385 4 4.615v13.03L5.65 16ZM4 16V4v12Z"
                      />
                    </svg>
                    Message
                  </div>

                  {messageCount ? (
                    <span className="bg-info badge"> {messageCount}</span>
                  ) : null}
                </div>
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname.includes('settings') ? 'active' : ''
                }
                to="/dashboard/settings"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-settings"
                >
                  <circle cx={12} cy={12} r={3} />
                  <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                </svg>
                Settings
              </Link>
            </li>
            <li>
              <Link to="/auth/login" onClick={logout}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-volume-1"
                >
                  <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
                  <path d="M15.54 8.46a5 5 0 0 1 0 7.07" />
                </svg>
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
