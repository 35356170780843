import { Link } from "react-router-dom";

export default function About() {
  return (
    <div>
      <div className="aboutarea__5 sp_bottom_100 sp_top_100">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6" data-aos="fade-up">
              <div className="aboutarea__5__img" data-tilt>
                <img src="img/about/about_14.png" alt="about" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6" data-aos="fade-up">
              <div className="aboutarea__content__wraper__5">
                <div className="section__title">
                  <div className="section__title__button">
                    <div className="default__small__button">About us</div>
                  </div>
                  <div className="section__title__heading ">
                    <h2>Learning is our passion</h2>
                  </div>
                </div>
                <div className="about__text__5">
                  <p>
                    UTA University is a prestigious institution offering quality
                    education in various disciplines. Our mission is to provide
                    students with the knowledge and skills they need to succeed
                    in their respective fields.
                  </p>
                </div>
                <div className="aboutarea__5__small__icon__wraper">
                  <div className="aboutarea__5__small__icon">
                    <img src="img/about/about_15.png" alt="about" />
                  </div>
                  <div className="aboutarea__small__heading">
                    <span>10+ Years of Experience</span>
                  </div>
                </div>
                <div className="aboutarea__para__5">
                  <p>
                    We transform the way people learn through our extensive
                    curriculum. Our goal is to provide students with the
                    knowledge and skills they need to succeed in their
                    respective fields.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="abouttabarea sp_bottom_70">
        <div className="container">
          <div className="row">
            <div className="col-xl-12" data-aos="fade-up">
              <ul
                className="nav  about__button__wrap"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="single__tab__link active"
                    data-bs-toggle="tab"
                    data-bs-target="#projects__one"
                    type="button"
                  >
                    About Us
                  </button>
                </li>
              </ul>
            </div>
            <div
              className="tab-content tab__content__wrapper"
              id="myTabContent"
              data-aos="fade-up"
            >
              <div
                className="tab-pane fade active show"
                id="projects__one"
                role="tabpanel"
                aria-labelledby="projects__one"
              >
                <div className="col-xl-12">
                  <div className="aboutarea__content__tap__wraper">
                    <p className="paragraph__1">
                      Founded in 1895, The University of Texas at Arlington is a
                      Carnegie Research 1 institution with more than 100 years
                      of academic excellence and tradition. The second largest
                      university in The University of Texas System, UTA is
                      located in the heart of Dallas-Fort Worth, challenging our
                      students to engage with the world around them in ways that
                      make a measurable impact. UTA offers state-of-the-art
                      facilities that encourage students to be critical
                      thinkers. Through academic, internship, and research
                      programs, our students receive real-world experiences that
                      help them contribute to their community and, ultimately,
                      the world. We have more than 180 baccalaureate, master's,
                      and doctoral degree programs, and more than 41,000
                      students walking our campus or engaging in online
                      coursework each year.
                    </p>
                    <div className="aboutarea__tap__heading">
                      <h5>Our Vision</h5>
                      <p>
                        To be a world-class university recognized for academic
                        excellence and innovation in research.
                      </p>
                    </div>
                    <div className="aboutarea__tap__heading">
                      <h5>Our Values</h5>
                      <p>
                        <ul>
                          <li>Integrity</li>
                          <br />
                          <li>Respect</li>
                          <br />
                          <li>Innovation</li>
                          <br />
                          <li>Excellence</li>
                          <br />
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="brandarea sp_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12" data-aos="fade-up">
              <div className="section__title text-center">
                <div className="section__title__heading heading__underline mt-5">
                  <h2>
                    Relied on marketers trusted by engineers and
                    <br />
                    Beloved by <span>Executives</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="brandarea__wraper" data-aos="fade-up">
              <div className="brandarea__img">
                <Link>
                  <img src="img/brand/brand_1.png" alt="brand" />
                </Link>
              </div>
              <div className="brandarea__img">
                <Link>
                  <img src="img/brand/brand_2.png" alt="brand" />
                </Link>
              </div>
              <div className="brandarea__img">
                <Link>
                  <img src="img/brand/brand_3.png" alt="brand" />
                </Link>
              </div>
              <div className="brandarea__img">
                <Link>
                  <img src="img/brand/brand_4.png" alt="brand" />
                </Link>
              </div>
              <div className="brandarea__img">
                <Link>
                  <img src="img/brand/brand_5.png" alt="brand" />
                </Link>
              </div>
              <div className="brandarea__img">
                <Link>
                  <img src="img/brand/brand_6.png" alt="brand" />
                </Link>
              </div>
              <div className="brandarea__img">
                <Link>
                  <img src="img/brand/brand_7.png" alt="brand" />
                </Link>
              </div>
              <div className="brandarea__img">
                <Link>
                  <img src="img/brand/brand_8.png" alt="brand" />
                </Link>
              </div>
              <div className="brandarea__img">
                <Link>
                  <img src="img/brand/brand_9.png" alt="brand" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
