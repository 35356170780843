function Blog() {
  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <iframe
          src="https://nxk6874.uta.cloud/"
          title="Blog"
          style={{ width: "100%", height: "80vh", border: "none" }}
        />
      </div>
    </div>
  );
}

export default Blog;
