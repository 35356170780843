import { Link } from 'react-router-dom'

function CoordinatorActions() {
  const userRole = localStorage.getItem('userRole')

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Dashboard</h4>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link
                      to={'/dashboard/' + userRole + '/program-implementation'}
                    >
                      Program Implementation
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link
                      to={'/dashboard/' + userRole + '/support-and-enquiries'}
                    >
                      Support & Enquiries
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={'/dashboard/' + userRole + '/reports'}>
                      Reports
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoordinatorActions
