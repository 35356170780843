import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import BASE_URL from '../../../config'
import axios from 'axios'
import Select from 'react-select'

function CurriculumDevelopment() {
  const baseUrl = BASE_URL
  const token = localStorage.getItem('authToken')
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  let isError = false

  const [allCourses, setAllCourses] = useState([])
  const [programs, setPrograms] = useState([])
  const [program, setProgram] = useState(null)
  const [programNames, setProgramNames] = useState([])
  const [name, setName] = useState('')
  const [curriculum, setCurriculum] = useState('')
  const [goals, setGoals] = useState('')
  const [courses, setCourses] = useState([])

  useEffect(() => {
    if (!programs.length) {
      getPrograms()
      getAllCourses()
    }
  })

  const getPrograms = () => {
    axios
      .get(baseUrl + '/programs', config)
      .then(response => {
        setPrograms(response.data.programs)
        const progs = []
        response.data.programs.forEach(program => {
          progs.push({ value: program.id, label: program.name })
        })
        setProgramNames(progs)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const getAllCourses = () => {
    axios
      .get(baseUrl + '/courses', config)
      .then(response => {
        const cours = []
        response.data.courses.forEach(course => {
          cours.push({ value: course.id, label: course.name })
        })
        setAllCourses(cours)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const handleSubmit = e => {
    e.preventDefault()

    let fieldName = []
    if (!name.length) {
      fieldName.push('Program')
    }
    if (!curriculum.length) {
      fieldName.push('Curriculum')
    }
    if (!goals.length) {
      fieldName.push('Goals')
    }
    if (!courses.length) {
      fieldName.push('Course')
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + ' is Required!')
      }
      isError = true
    }

    if (isError) {
      return
    }
    updateProgram()
  }

  const clearFields = () => {
    setProgram(null)
    setName('')
    setCurriculum('')
    setGoals('')
    setCourses([])
  }

  const editProgram = prog => {
    setProgram(prog)
    setName(prog.name)
    setCurriculum(prog.curriculum)
    setGoals(prog.goals)
    setCourses(
      prog.courses.map(course => {
        return { value: course.id, label: course.name }
      })
    )
  }

  const updateProgram = () => {
    axios
      .post(
        baseUrl + '/program/' + program.id,
        {
          coordinator_id: program.coordinator_id,
          name: name,
          description: program.description,
          duration: program.duration,
          alignment: program.alignment,
          curriculum: curriculum,
          goals: goals,
          evaluation: program.evaluation,
          courses: courses.map(course => course.value),
          status: program.status,
        },
        config
      )
      .then(response => {
        toast.success(
          'Curriculum Development and Updates Updated Successfully!'
        )
        getPrograms()
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })

    clearFields()
  }

  const handleProgramChange = selectedValue => {
    const prog = programs.find(program => program.id === selectedValue.value)
    editProgram(prog)
  }

  const handleCourseSelection = selectedValues => {
    setCourses(selectedValues)
  }
  return (
    <div className="col-9">
      <div className="dashboard__content__wraper">
        <div className="dashboard__section__title">
          <h4>Curriculum Development and Updates</h4>
        </div>
        <div className="card-body p-2 w-100 border-0 mb-0">
          <form>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss">Program</label>
                  <Select
                    isSearchable={false}
                    options={programNames}
                    value={programNames.find(
                      option => option.value === program?.id
                    )}
                    onChange={handleProgramChange}
                    placeholder="Select Program"
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss">Courses</label>
                  <Select
                    isMulti
                    options={allCourses}
                    onChange={handleCourseSelection}
                    value={courses}
                    placeholder="Select Courses"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss">
                    Curriculum
                  </label>
                  <textarea
                    className="w-100 h100 style2-textarea p-3 form-control"
                    onChange={e => setCurriculum(e.target.value)}
                    value={curriculum}
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss">Goals</label>
                  <textarea
                    className="w-100 h100 style2-textarea p-3 form-control"
                    onChange={e => setGoals(e.target.value)}
                    value={goals}
                  />
                </div>
              </div>

              <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
                <Link
                  className="default__button"
                  onClick={e => handleSubmit(e)}
                >
                  Update Program Overview and Alignment
                </Link>
              </div>
            </div>
          </form>
        </div>
        {programs.length > 0 && (
          <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
            <table className="table table-bordered data-table">
              <thead>
                <tr>
                  <th>Program ID</th>
                  <th>Program Name</th>
                  <th>Curriculum</th>
                  <th>Goals</th>
                  <th>Courses</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {programs.map(program => (
                  <tr key={program.id}>
                    <td>{program.id}</td>
                    <td>{program.name}</td>
                    <td>{program.curriculum}</td>
                    <td>{program.goals}</td>
                    <td>
                      {program.courses.map(course => course.name).join(', ')}
                    </td>
                    <td className="text-center">
                      <Link onClick={() => editProgram(program)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-edit"
                        >
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                        </svg>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default CurriculumDevelopment
