import { useEffect, useRef, useState } from 'react'
import BASE_URL from '../../../config'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setMessageCount } from '../../../redux/slices/app'

function Message() {
  const dispatch = useDispatch()
  const baseUrl = BASE_URL
  const user = JSON.parse(localStorage.getItem('user'))
  const token = localStorage.getItem('authToken')
  const [chatsLoaded, setChatsLoaded] = useState(false)
  const [allChats, setAllChats] = useState([])
  const [chats, setChats] = useState([])
  const [conversation, setConversation] = useState([])
  const [activeChat, setActiveChat] = useState(null)
  const [chatHeader, setChatHeader] = useState(null)
  const [message, setMessage] = useState('')
  const ref = useRef(null)

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  useEffect(() => {
    if (!chatsLoaded) {
      fetchChats()
    }
    if (activeChat) {
      if (ref.current) scrollToBottom()
    }
  })

  const fetchUsers = () => {
    const groupedChats = []
    axios
      .get(baseUrl + '/users', config)
      .then(response => {
        const usersList = response.data.users
        for (const userItem of usersList) {
          if (userItem.id !== user.id) {
            groupedChats.push({
              id: userItem.id,
              user: userItem,
              message: '',
              unread: 0,
            })
          }
        }
        setChats(groupedChats)
      })
      .catch(function (error) {
        const tempdata = error?.response?.data
        if (tempdata) toast.error(`${tempdata.message}`)
      })
  }

  const fetchChats = () => {
    axios
      .get(baseUrl + '/chats', config)
      .then(response => {
        if (!response.data.chats.length) {
          fetchUsers()
        } else {
          setAllChats(response.data.chats)
          groupChats(response.data.chats)
        }
      })
      .catch(function (error) {
        const tempdata = error?.response?.data
        if (tempdata) toast.error(`${tempdata.message}`)
      })
    setChatsLoaded(true)
  }

  const groupChats = chats => {
    const groupedChats = []
    for (const chat of chats) {
      const senderId = chat.sender_id
      const receiverId = chat.receiver_id

      if (senderId === user.id) {
        handleSenderChat(groupedChats, receiverId, chat)
      }

      if (receiverId === user.id) {
        handleReceiverChat(groupedChats, senderId, chat)
      }
    }
    setChats(groupedChats)
  }

  const handleSenderChat = (groupedChats, receiverId, chat) => {
    const chatExist = groupedChats.some(
      groupedChat => groupedChat.id === receiverId
    )
    if (!chatExist) {
      groupedChats.push({
        id: receiverId,
        user: chat.receiver,
        message: chat.message,
        unread: 0,
      })
    }
  }

  const handleReceiverChat = (groupedChats, senderId, chat) => {
    const chatExist = groupedChats.some(
      groupedChat => groupedChat.id === senderId
    )
    if (!chatExist) {
      groupedChats.push({
        id: senderId,
        user: chat.sender,
        message: chat.message,
        unread: chat.status === 'unread' ? 1 : 0,
      })
    } else {
      groupedChats.find(groupedChat => groupedChat.id === senderId).unread +=
        chat.status === 'unread' ? 1 : 0
    }
  }

  const selectChat = id => {
    let clone = [...chats]
    clone.find(chat => chat.id === id).unread = 0
    setChats(clone)

    setActiveChat(id)
    setChatHeader(chats.find(chat => chat.id === id).user)
    fetchConversation(id)
  }

  const fetchConversation = id => {
    config.params = {
      receiver_id: id,
    }

    axios
      .get(baseUrl + '/conversation', config)
      .then(response => {
        setConversation(response.data.conversation)
      })
      .catch(function (error) {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const sendMessage = () => {
    const formData = new FormData()
    formData.append('sender_id', user.id)
    formData.append('receiver_id', activeChat)
    formData.append('message', message)

    axios
      .post(baseUrl + '/chat', formData, config)
      .then(response => {
        setMessage('')
        fetchConversation(activeChat)
        fetchChats()
      })
      .catch(function (error) {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const scrollToBottom = () => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }

  useEffect(() => {
    // get sum of unread messages using reduce
    const sum = chats.reduce((a, b) => a + b.unread, 0)
    dispatch(setMessageCount(sum))
  }, [chats, dispatch])

  return (
    <div className="col-xl-9 col-lg-9 col-md-12">
      <div className="dashboard__message__content__main">
        <div className="dashboard__message__content__main__title dashboard__message__content__main__title__2">
          <h3>Messages</h3>
        </div>
        <div className="dashboard__meessage__wraper">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-12 col-12">
              <div className="dashboard__meessage">
                <div className="dashboard__meessage__chat">
                  <h3>Chats</h3>
                </div>
                <div className="dashboard__meessage__search">
                  <button>
                    <i className="icofont-search-1" />
                  </button>
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={e => {
                      if (e.target.value === '') {
                        groupChats(allChats)
                      } else if (
                        chats.length > 0 &&
                        e.target.value.length > 1
                      ) {
                        setChats(
                          chats.filter(
                            chat =>
                              chat.id !== user.id &&
                              chat.user.name
                                .toLowerCase()
                                .startsWith(e.target.value.toLowerCase())
                          )
                        )
                      } else {
                        fetchUsers()
                      }
                    }}
                  />
                </div>
                <div className="dashboard__meessage__contact">
                  <ul>
                    {chats.map(chat => (
                      <li
                        onClick={() => selectChat(chat.id)}
                        onKeyDown={e => {
                          e.preventDefault()
                        }}
                        key={chat.id}
                      >
                        <div className="dashboard__meessage__contact__wrap">
                          <div className="dashboard__meessage__chat__img">
                            <span className="dashboard__meessage__dot online" />
                            <div>
                              <img src={chat.user.image} alt="instructor" />
                            </div>
                          </div>
                          <div className="dashboard__meessage__meta">
                            <h5>{chat.user.name}</h5>
                            <p className="preview">{chat.message}</p>
                          </div>
                          {chat.unread ? (
                            <span
                              className="chat__time"
                              style={{
                                position: 'absolute',
                                background: '#039be5',
                                color: 'white',
                                padding: '5px 10px',
                                borderRadius: '8px',
                                right: 0,
                              }}
                            >
                              {chat.unread}
                            </span>
                          ) : null}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12 col-12">
              <div className="dashboard__meessage__content__wrap">
                {activeChat ? (
                  <>
                    <div className="dashboard__meessage__profile">
                      <div className="dashboard__meessage__profile__img">
                        <img src={chatHeader.image} alt="instructor" />
                      </div>
                      <div className="dashboard__meessage__profile__meta">
                        <h5>{chatHeader.name}</h5>
                      </div>
                    </div>
                    <div
                      style={{ height: '44vh' }}
                      className="dashboard__meessage__sent"
                    >
                      <ul>
                        {conversation?.map(conv => (
                          <li
                            style={{ marginBottom: '15px' }}
                            className={
                              conv.sender_id === user.id
                                ? 'dashboard__meessage__outgoing'
                                : 'sent'
                            }
                            key={conv.id}
                          >
                            {conv.sender_id !== user.id && (
                              <div className="dashboard__meessage__sent__item__img">
                                <img src={conv.sender.image} alt="instructor" />
                              </div>
                            )}
                            <div className="dashboard__meessage__sent__item__content">
                              <p style={{ margin: 0 }}>{conv.message}</p>
                              <span
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  textAlign:
                                    conv.sender_id === user.id
                                      ? 'right'
                                      : 'left',
                                }}
                              >
                                {conv.sender.name}
                              </span>
                              <span
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  textAlign:
                                    conv.sender_id === user.id
                                      ? 'right'
                                      : 'left',
                                }}
                              >
                                {new Date(conv.created_at).toLocaleTimeString()}
                              </span>
                            </div>
                            {conv.sender_id === user.id && (
                              <div
                                style={{ marginLeft: '10px' }}
                                className="dashboard__meessage__sent__item__img"
                              >
                                <img src={conv.sender.image} alt="instructor" />
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="dashboard__meessage__input">
                      <input
                        type="text"
                        placeholder="Type something"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            sendMessage()
                          }
                        }}
                      />
                      <i
                        className="icofont-attachment attachment"
                        aria-hidden="true"
                      />
                      <button className="submit" onClick={sendMessage}>
                        <i className="icofont-arrow-right" />
                      </button>
                    </div>
                  </>
                ) : (
                  <div
                    className="w-100 bg-white"
                    style={{ height: '500px' }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Message
