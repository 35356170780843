import { Link } from 'react-router-dom'

function InstructorActions() {
  const userRole = localStorage.getItem('userRole')

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Dashboard</h4>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={'/dashboard/' + userRole + '/course-management'}>
                      Course Management
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={'/dashboard/' + userRole + '/course-content'}>
                      Course Content
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link
                      to={'/dashboard/' + userRole + '/assessment-creation'}
                    >
                      Assessment Creation
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={'/dashboard/' + userRole + '/course-materials'}>
                      Course Materials
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={'/dashboard/' + userRole + '/manage-assignments'}>
                      Manage Assignments
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={'/dashboard/' + userRole + '/manage-submissions'}>
                      Manage Submissions
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={'/dashboard/' + userRole + '/grade-student'}>
                      Grade Student
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstructorActions
