/* eslint-disable no-param-reassign */

import axios from 'axios'
import BASE_URL from '../config'

export const TOKEN = localStorage.getItem('authToken')

async function api({
  headers = {
    Authorization: '',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  baseURL = BASE_URL,
  method = 'GET',
  data,
  url = '',
  params,
}) {
  try {
    headers.Authorization = `Bearer ${TOKEN}`

    const { data: response } = await axios({
      method,
      url,
      headers,
      data,
      baseURL,
      params,
      // withCredentials: true,
    })

    return response
  } catch (e) {
    if (e?.response?.data) {
      const errorData = e.response.data

      let errorMessage = errorData.error

      if (errorData.error?.errors) {
        const errors = errorData.error?.errors
        errorMessage = errors.length
          ? errors[0].message
          : 'Something went wrong!'
      }

      throw typeof errorMessage === 'string'
        ? errorMessage
        : 'Something went wrong!'
    }
    throw e
  }
}

export default api
