import { Link } from "react-router-dom";

export default function Topbar() {
  return (
    <div className="topbararea">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="topbar__left">
              <ul>
                <li>Call Us: +1 817-272-2011</li>
                <li>&nbsp;- Mail Us: uta@mail.com</li>
              </ul>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="topbar__right">
              <div className="topbar__icon">
                <i className="icofont-location-pin" />
              </div>
              <div className="topbar__text">
                <p>701 S Nedderman Dr, Arlington, TX 76019, United States</p>
              </div>
              <div className="topbar__list">
                <ul>
                  <li>
                    <Link>
                      <i className="icofont-facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="icofont-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="icofont-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="icofont-youtube-play" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
