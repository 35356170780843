import axios from 'axios'
import React, { useEffect, useState } from 'react'

import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import BASE_URL from '../../../config'

function StudentProgress() {
  const baseUrl = BASE_URL
  const token = localStorage.getItem('authToken')
  const user = JSON.parse(localStorage.getItem('user'))
  const student_id = user.id

  const [exams, setExams] = useState([])
  const [grades, setGrades] = useState([])

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  useEffect(() => {
    if (!exams.length) {
      getExams()
      getGrades()
      getSubmissions()
    }
  })

  const getExams = () => {
    axios
      .get(baseUrl + '/exams', config)
      .then(response => {
        setExams(response.data.exams)
      })
      .catch(function (error) {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const getGrades = () => {
    axios
      .get(baseUrl + '/exam-scores', config)
      .then(response => {
        setGrades(response.data.grades)
      })
      .catch(function (error) {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const [submissions, setSubmissions] = useState([])

  const getSubmissions = () => {
    axios
      .get(baseUrl + '/submissions', config)
      .then(response => {
        setSubmissions(response.data.submissions)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  return (
    <div className="col-xl-9 col-lg-9 col-md-12">
      <div className="dashboard__content__wraper">
        <div className="dashboard__section__title">
          <h2>My Progress</h2>
        </div>
        <div className="row gap-4">
          {exams?.length > 0
            ? exams.map(item => (
                <>
                  {grades.filter(
                    grade =>
                      grade.exam_id === item.id &&
                      grade.student_id === student_id
                  ).length > 0 && (
                    <div
                      className="col-xxl-4 col-xl-4 col-md-12 border shadow-sm rounded"
                      key={item.id}
                    >
                      <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-4 border-0 text-center">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="45"
                            height="45"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M202.24 74C166.11 56.75 115.61 48.3 48 48a31.36 31.36 0 0 0-17.92 5.33A32 32 0 0 0 16 79.9V366c0 19.34 13.76 33.93 32 33.93c71.07 0 142.36 6.64 185.06 47a4.11 4.11 0 0 0 6.94-3V106.82a15.89 15.89 0 0 0-5.46-12A143 143 0 0 0 202.24 74Zm279.68-20.7A31.33 31.33 0 0 0 464 48c-67.61.3-118.11 8.71-154.24 26a143.31 143.31 0 0 0-32.31 20.78a15.93 15.93 0 0 0-5.45 12v337.13a3.93 3.93 0 0 0 6.68 2.81c25.67-25.5 70.72-46.82 185.36-46.81a32 32 0 0 0 32-32v-288a32 32 0 0 0-14.12-26.61Z"
                            />
                          </svg>
                        </div>
                        <h4 className="fw-700 font-xs mt-4">{item.name}</h4>
                        <div className="clearfix" />

                        <div className="clearfix" />
                        <div className="card-body p-0 w250 ml-auto mr-auto">
                          <div className="timer mt-4 mb-2" />
                        </div>

                        <div>
                          <Link className="btn btn-success py-2 px-5 btn-sm mt-4">
                            Score :{' '}
                            {
                              grades.filter(
                                grade =>
                                  grade.exam_id === item.id &&
                                  grade.student_id === student_id
                              )[0].score
                            }{' '}
                            | Grade :{' '}
                            {grades.filter(
                              grade =>
                                grade.exam_id === item.id &&
                                grade.student_id === student_id
                            )[0].grade || 'Yet to Grade'}
                          </Link>
                        </div>

                        <button className="btn btn-warning btn-sm disabled position-absolute top-0 end-0 rounded-pill m-2">
                          Exam
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ))
            : 'No Exams Taken'}
          {submissions?.length > 0 ? (
            submissions.map(item => (
              <div
                className="col-xxl-4 col-xl-4 col-md-12 border shadow-sm"
                key={item.id}
              >
                <div className="card mb-4 d-block w-100 shadow-xss rounded-lg py-4 px-2 border-0 text-center">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45"
                      height="45"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M202.24 74C166.11 56.75 115.61 48.3 48 48a31.36 31.36 0 0 0-17.92 5.33A32 32 0 0 0 16 79.9V366c0 19.34 13.76 33.93 32 33.93c71.07 0 142.36 6.64 185.06 47a4.11 4.11 0 0 0 6.94-3V106.82a15.89 15.89 0 0 0-5.46-12A143 143 0 0 0 202.24 74Zm279.68-20.7A31.33 31.33 0 0 0 464 48c-67.61.3-118.11 8.71-154.24 26a143.31 143.31 0 0 0-32.31 20.78a15.93 15.93 0 0 0-5.45 12v337.13a3.93 3.93 0 0 0 6.68 2.81c25.67-25.5 70.72-46.82 185.36-46.81a32 32 0 0 0 32-32v-288a32 32 0 0 0-14.12-26.61Z"
                      />
                    </svg>
                  </div>
                  <h4 className="fw-700 font-xs mt-4">
                    {item?.assignment?.title}
                  </h4>

                  <div className="px-5 d-flex mt-4 align-items-center justify-content-between">
                    <h6 className="mb-0 ">Feedback</h6>
                    <p className="mb-0">
                      {item.feedback || 'Yet to be reviewed'}
                    </p>
                  </div>

                  <div className="px-5 d-flex mt-2 align-items-center justify-content-between">
                    <h6 className="mb-0 ">Grade</h6>
                    <p className="mb-0">{item.grade || 'Yet to be reviewed'}</p>
                  </div>

                  <button className="btn btn-info btn-sm disabled position-absolute top-0 end-0 rounded-pill m-2">
                    Assignment
                  </button>
                </div>
              </div>
            ))
          ) : (
            <h6 className="text-center mt-5">
              No Assignment Submissions made yet
            </h6>
          )}
        </div>
      </div>
    </div>
  )
}

export default StudentProgress
