import { Outlet, useNavigate } from 'react-router-dom'
import Navigation from './Navigation'
import DashboardTop from './DashboardTop'
import Stats from '../../../stats'

function DashboardLayout() {
  useNavigate()

  var stats = new Stats()
  stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
  document.body.appendChild(stats.dom)

  function animate() {
    stats.begin()

    stats.end()

    requestAnimationFrame(animate)
  }

  requestAnimationFrame(animate)

  return (
    <div>
      <div className="body__wrapper">
        <div className="dashboardarea sp_bottom_100 mt-4">
          <DashboardTop />
          <div className="dashboard">
            <div className="container-fluid">
              <div className="row">
                <Navigation />
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout
