import { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import validator from 'validator'
import axios from 'axios'
import BASE_URL from '../../../config'

export default function SignUp() {
  const authToken = localStorage.getItem('authToken')
  const userRole = localStorage.getItem('userRole')
  const baseUrl = BASE_URL

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const [role, setRole] = useState('')

  const roleOptions = [
    { value: 'instructor', label: 'Instructor' },
    { value: 'coordinator', label: 'Program Coordinator' },
    { value: 'qa', label: 'QA Officer' },
    { value: 'student', label: 'Student' },
  ]

  let isError = false
  const handleSubmit = e => {
    e.preventDefault()

    let fieldName = []
    if (!name.length) {
      fieldName.push('Name')
    }

    if (!email.length) {
      fieldName.push('Email')
    }
    if (!phone.length) {
      fieldName.push('Phone')
    }
    if (!password.length) {
      fieldName.push('Password')
    }
    if (!confirmPassword.length) {
      fieldName.push('Confirm Password')
    }

    if (!role.length) {
      fieldName.push('Role')
    }

    if (!termsAndConditions) {
      toast.error('Please accept Terms and Privacy Policy!')
      isError = true
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + ' is Required!')
      }
      isError = true
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error('Email is Invalid!')
      isError = true
    }
    if (password !== confirmPassword) {
      toast.error('Passwords do not match!')
      isError = true
    }

    const phoneValidation = /^[+]?\d+$/
    if (
      phone.length &&
      !validator.isMobilePhone(phone) &&
      !phoneValidation.test(phone)
    ) {
      toast.error('Phone Number is Invalid!')
      isError = true
    }

    if (isError) {
      return
    }
    signUp()
  }

  function signUp() {
    axios
      .post(baseUrl + '/register', {
        name: name,
        email: email,
        phone: phone,
        password: password,
        password_confirmation: confirmPassword,
        role: role,
      })
      .then(response => {
        const data = response.data
        localStorage.setItem('authToken', data.token)
        toast.info("We've sent a verification email to " + email)
        toast.success('Sign Up Successful!')
        setName('')
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        setPhone('')
        setTermsAndConditions(false)
      })
      .catch(function (error) {
        const tempdata = error.response.data

        if (tempdata.errors) {
          const errorKeys = Object.keys(tempdata.errors)
          for (const errorKey of errorKeys) {
            for (const error of tempdata.errors[errorKey]) {
              toast.error(`${error}`)
            }
          }
        }
      })
  }

  return authToken && userRole ? (
    <Navigate to={'/dashboard/' + userRole} />
  ) : (
    <div className="loginarea sp_top_100 sp_bottom_100">
      <div className="container">
        <div className="row">
          <div
            className="tab-content tab__content__wrapper"
            id="myTabContent"
            data-aos="fade-up"
          >
            <div>
              <div className="col-xl-8 offset-md-2">
                <div className="loginarea__wraper">
                  <div className="login__heading">
                    <h5 className="login__title">Sign Up</h5>
                    <p className="login__description">
                      Already have an account?{' '}
                      <Link to="/auth/login">Log In</Link>
                    </p>
                  </div>
                  <form action="#">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="login__form">
                          <label className="form__label">Name</label>
                          <input
                            className="common__login__input"
                            type="text"
                            placeholder="Name"
                            onChange={e => setName(e.target.value)}
                            value={name}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="login__form">
                          <label className="form__label">Email</label>
                          <input
                            className="common__login__input"
                            type="email"
                            placeholder="Your Email"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="login__form">
                          <label className="form__label">Phone</label>
                          <input
                            type="tel"
                            className="common__login__input"
                            placeholder="Phone"
                            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                            onChange={e => setPhone(e.target.value)}
                            value={phone}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="login__form">
                          <label className="form__label">Password</label>
                          <input
                            className="common__login__input"
                            type="password"
                            placeholder="Password"
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="login__form">
                          <label className="form__label">
                            Re-Enter Password
                          </label>
                          <input
                            className="common__login__input"
                            type="password"
                            placeholder="Re-Enter Password"
                            onChange={e => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="login__form">
                          <label className="form__label">Select Role</label>
                          <select
                            className="common__login__input"
                            onChange={e => setRole(e.target.value)}
                          >
                            <option value="Select Role">Select Role</option>
                            {roleOptions.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="login__form d-flex justify-content-between flex-wrap gap-2">
                      <div className="form__check">
                        <input
                          id="accept_pp"
                          type="checkbox"
                          onChange={e => setTermsAndConditions(e.target.value)}
                          value={termsAndConditions}
                        />{' '}
                        <label htmlFor="accept_pp">
                          Accept the Terms and Privacy Policy
                        </label>
                      </div>
                    </div>
                    <div className="login__button">
                      <Link
                        className="default__button"
                        onClick={e => handleSubmit(e)}
                      >
                        Sign Up
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" login__shape__img educationarea__shape_image">
          <img
            className="hero__shape hero__shape__1"
            src="/img/education/hero_shape2.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__2"
            src="/img/education/hero_shape3.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__3"
            src="/img/education/hero_shape4.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__4"
            src="/img/education/hero_shape5.png"
            alt="Shape"
          />
        </div>
      </div>
    </div>
  )
}
