import BASE_URL from '../../../src/config'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function Courses() {
  const baseUrl = BASE_URL

  const [courses, setCourses] = useState([])

  useEffect(() => {
    if (!courses.length) {
      getCourses()
    }
  })

  const getCourses = () => {
    axios
      .get(baseUrl + '/courses')
      .then(response => {
        setCourses(response.data.courses)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  return (
    <div
      className="coursearea sp_top_100 sp_bottom_100"
      style={{ height: '75vh' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div
              className="tab-content tab__content__wrapper"
              id="myTabContent"
            >
              <div
                className="tab-pane fade  active show"
                id="projects__one"
                role="tabpanel"
                aria-labelledby="projects__one"
              >
                <div className="row">
                  {courses?.length > 0 &&
                    courses.map(course => (
                      <div
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
                        data-aos="fade-up"
                        key={course._id}
                      >
                        <div className="gridarea__wraper gridarea__wraper__2">
                          <div className="gridarea__content">
                            <div className="gridarea__list">
                              <ul>
                                <li>
                                  <i className="icofont-book-alt" />{' '}
                                  {course.sections.length} Section(s)
                                </li>
                                <li>
                                  <i className="icofont-clock-time" />{' '}
                                  {course.duration}
                                </li>
                              </ul>
                            </div>
                            <div className="gridarea__heading">
                              <h3>{course.name}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
