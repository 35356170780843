import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import BASE_URL from '../../../config'
import axios from 'axios'

function SupportAndEnquiries() {
  const baseUrl = BASE_URL
  const token = localStorage.getItem('authToken')
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    query: '',
    resolution: '',
  })

  const [dataLoaded, setDataLoaded] = useState(false)
  const [supports, setSupports] = useState([])
  const [supportId, setSupportId] = useState(null)

  useEffect(() => {
    if (!dataLoaded) {
      getSupports()
    }
  })

  const getSupports = () => {
    axios
      .get(baseUrl + '/supports', config)
      .then(response => {
        setSupports(response.data.supports)
        setDataLoaded(true)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const handleEdit = id => {
    setSupportId(id)
    const support = supports.find(support => support.id === id)
    setFormData({
      name: support.name,
      email: support.email,
      query: support.query,
      resolution: support.resolution || '',
    })
  }

  const handleResolve = e => {
    e.preventDefault()
    if (!supportId) {
      toast.error('Please select a Support Query!')
      return
    }
    axios
      .post(baseUrl + '/support/' + supportId, formData, config)
      .then(response => {
        toast.success(response.data.message)
        setFormData({
          name: '',
          email: '',
          query: '',
          resolution: '',
        })
        getSupports()
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  return (
    <div className="col-9">
      <div className="dashboard__content__wraper">
        <div className="dashboard__section__title">
          <h4>Support & Enquiries</h4>
        </div>
        <div className="card-body p-1 w-100 border-0 mb-0">
          <form action="#">
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    name="courseName"
                    className="form-control"
                    value={formData.name}
                    onChange={e =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label className="form-label">Email</label>
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    value={formData.email}
                    onChange={e =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label className="form-label">Query</label>
                  <textarea
                    className="w-100 h100 style2-textarea p-3 form-control"
                    name="query"
                    value={formData.query}
                    onChange={e =>
                      setFormData({ ...formData, query: e.target.value })
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label className="form-label">Resolution</label>
                  <textarea
                    className="w-100 h100 style2-textarea p-3 form-control"
                    name="resolution"
                    value={formData.resolution}
                    onChange={e =>
                      setFormData({ ...formData, resolution: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
                <Link className="btn btn-primary" onClick={handleResolve}>
                  Resolve Query
                </Link>
              </div>
            </div>
          </form>
        </div>
        {supports.length ? (
          <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
            <table className="table table-bordered data-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Query</th>
                  <th>Resolution</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {supports.length ? (
                  supports.map(support => (
                    <tr key={support.id}>
                      <td>{support.name}</td>
                      <td>{support.email}</td>
                      <td>{support.query}</td>
                      <td>{support.resolution}</td>
                      <td className="text-center">
                        <i
                          className="feather-edit text-current"
                          style={{ cursor: 'pointer' }}
                          onKeyDown={e => {}}
                        />
                        <Link
                          onClick={() => handleEdit(support.id)}
                          className="btn btn-outline-primary"
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className="text-center p-4"> No Enquiries</p>
                )}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default SupportAndEnquiries
