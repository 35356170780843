import { Link, useNavigate, useParams } from 'react-router-dom'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import BASE_URL from '../../config'

export default function CourseDetails() {
  const { courseId } = useParams()

  const navigate = useNavigate()
  const baseUrl = BASE_URL
  const token = localStorage.getItem('authToken')
  const user = JSON.parse(localStorage.getItem('user'))
  const course_id = courseId

  const [courseDetails, setCourseDetails] = useState(null)
  const [studentCourses, setStudentCourses] = useState([])
  const [courseMaterials, setCourseMaterials] = useState([])
  const [courseAssignments, setCourseAssignments] = useState([])

  const [submissionFile, setSubmissionFile] = useState(null)
  const [submissions, setSubmissions] = useState([])

  useEffect(() => {
    if (!course_id) {
      navigate(`/dashboard/${user.role}`)
    }
    if (!courseDetails) {
      getCourseDetails()
      getStudentCourses()
      getCourseMaterials()
      getCourseAssignments()
      getSubmissions()
    }
  })

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const getCourseDetails = () => {
    axios
      .get(baseUrl + '/course/' + course_id, config)
      .then(response => {
        setCourseDetails(response.data.course)
      })
      .catch(function (error) {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const getStudentCourses = () => {
    axios
      .get(baseUrl + '/admin/my-courses', config)
      .then(response => {
        setStudentCourses(response.data.myCourses)
      })
      .catch(error => {
        toast.error(`${error}`)
      })
  }

  const handleCourseRegistration = e => {
    e.preventDefault()
    const crs = studentCourses.courses
      ? studentCourses.courses.map(cor => cor.id)
      : []
    const newStudent = {
      student_id: user.id,
      courses: [...crs, parseInt(course_id)],
    }

    if (crs.length > 0) {
      axios
        .post(
          baseUrl + '/admin/student-courses/' + studentCourses.id,
          newStudent,
          config
        )
        .then(response => {
          toast.success(response.data.message)
          getStudentCourses()
        })
        .catch(function (error) {
          const tempdata = error.response.data
          toast.error(`${tempdata.message}`)
        })
    } else {
      axios
        .post(baseUrl + '/admin/student-courses', newStudent, config)
        .then(response => {
          toast.success(response.data.message)
          getStudentCourses()
        })
        .catch(function (error) {
          const tempdata = error.response.data
          toast.error(`${tempdata.message}`)
        })
    }
  }

  const getCourseMaterials = () => {
    axios
      .get(baseUrl + '/materials/' + course_id, config)
      .then(response => {
        setCourseMaterials(response.data.materials)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const getCourseAssignments = () => {
    axios
      .get(baseUrl + '/assignments/' + course_id, config)
      .then(response => {
        setCourseAssignments(response.data.assignments)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const handleAssignmentSubmission = assignmentId => {
    if (!submissionFile) {
      toast.error('Please select a file!')
      return
    }
    const formData = new FormData()
    formData.append('assignment_id', assignmentId)
    formData.append('student_id', user.id)
    formData.append('file', submissionFile)

    axios
      .post(baseUrl + '/submission', formData, config)
      .then(response => {
        toast.success(response.data.message)
        getCourseAssignments()
        getSubmissions()
        setSubmissionFile(null)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const getSubmissions = () => {
    axios
      .get(baseUrl + '/submissions/' + course_id, config)
      .then(response => {
        setSubmissions(response.data.submissions)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const [show, setShow] = useState(null)

  if (!courseDetails) {
    return (
      <div>
        {/* loading */}
        loading...
      </div>
    )
  }

  return (
    <div className="col-xl-9 col-lg-9 col-md-12">
      <div className="container">
        <div className="row">
          <div className="col-xl-8">
            <div className="course__details__top--2">
              <div className="course__details__heading" data-aos="fade-up">
                <h3>{courseDetails.name}</h3>
              </div>
              <div className="course__details__price" data-aos="fade-up">
                <ul>
                  <li>
                    <div className="course__details__date">
                      <i className="icofont-book-alt" />{' '}
                      {courseDetails.sections.length} Lesson
                    </div>
                  </li>
                  <li>
                    <div className="course__star">
                      <i className="icofont-star" />
                      <i className="icofont-star" />
                      <i className="icofont-star" />
                      <i className="icofont-star" />
                      <i className="icofont-star" />
                      <span>&nbsp; {Math.ceil(courseDetails.rating)}</span>
                    </div>
                  </li>
                  <li>
                    <div className="course__date">
                      <p>
                        Last Update:
                        <span> {courseDetails.updated_at?.split('T')[0]}</span>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white blogarea__2 sp_top_100 sp_bottom_100">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8">
              <div className="blog__details__content__wraper">
                <div
                  className="course__details__tab__wrapper"
                  data-aos="fade-up"
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <ul
                        className="nav  course__tap__wrap"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="single__tab__link active"
                            data-bs-toggle="tab"
                            data-bs-target="#projects__one"
                            type="button"
                          >
                            <i className="icofont-book-alt" />
                            Curriculum
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="single__tab__link"
                            data-bs-toggle="tab"
                            data-bs-target="#projects__two"
                            type="button"
                          >
                            <i className="icofont-paper" />
                            Description
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className="single__tab__link"
                            data-bs-toggle="tab"
                            data-bs-target="#projects__four"
                            type="button"
                          >
                            <i className="icofont-teacher" />
                            Assignments
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="tab-content tab__content__wrapper"
                    id="myTabContent"
                  >
                    <div
                      className="tab-pane fade active show"
                      id="projects__one"
                      role="tabpanel"
                      aria-labelledby="projects__one"
                    >
                      <div
                        className="accordion content__cirriculum__wrap"
                        id="accordionExample"
                      >
                        {courseDetails.sections.map((section, sectionIdx) => (
                          <div
                            onClick={() => setShow(sectionIdx)}
                            className="accordion-item"
                          >
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className={`accordion-button ${
                                  show === sectionIdx ? '' : 'collapsed'
                                }`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                {section.name}{' '}
                              </button>
                            </h2>
                            <div
                              className={`accordion-collapse collapse
                            ${show === sectionIdx ? 'show' : ''}
                            `}
                            >
                              <div className="accordion-body">
                                {courseDetails.lessons[sectionIdx].map(
                                  (lesson, lessonIdx) => (
                                    <div
                                      key={lessonIdx + 1}
                                      className="scc__wrap"
                                    >
                                      <div className="scc__info">
                                        <i className="icofont-video-alt" />
                                        <h5> {lesson.name}</h5>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="projects__two"
                      role="tabpanel"
                      aria-labelledby="projects__two"
                    >
                      <div className="experence__heading">
                        <h5> Course Description</h5>
                      </div>
                      <div className="experence__description">
                        <p className="description__1">
                          {courseDetails.description}
                        </p>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="projects__four"
                      role="tabpanel"
                      aria-labelledby="projects__four"
                    >
                      <div className="blogsidebar__content__wraper__2 tab__instructor">
                        <div className="row">
                          {courseAssignments?.length > 0 ? (
                            courseAssignments.map(assignment => (
                              <div className="col-12 mb-3" key={assignment.id}>
                                <div className="border px-4 pb-4 pt-2 border-light mt-1">
                                  <h2 className="fw-700 font-sm mb-3 mt-1 pl-1 mb-3">
                                    {assignment.title}
                                  </h2>
                                  <p className="font-xssss fw-500 lh-28 text-grey-600 ml-1">
                                    {assignment.description}
                                  </p>
                                  <p className="font-xssss fw-500 lh-28 ml-1 text-danger">
                                    Due Date :{' '}
                                    {new Date(assignment.due).toLocaleString()}
                                  </p>
                                  <p className="font-xsss fw-500 lh-28 ml-1 text-grey-600">
                                    <label>
                                      Upload your submission file here :<br />
                                      <span className="text-info font-xssss">
                                        Max File Size {'<='} 2 MB
                                      </span>
                                      <br />
                                      <span className="text-info font-xssss">
                                        Supported File Types : pdf, doc, docx
                                      </span>
                                    </label>
                                    <br />
                                    {submissions?.length > 0 &&
                                    submissions.some(
                                      submission =>
                                        submission.assignment_id ===
                                        assignment.id
                                    ) ? null : (
                                      <input
                                        className="font-xssss fw-500 lh-28 text-grey-600"
                                        type="file"
                                        name="material-file"
                                        accept=".pdf, .doc, .docx"
                                        onChange={e =>
                                          setSubmissionFile(e.target.files[0])
                                        }
                                      />
                                    )}
                                  </p>
                                  <hr />
                                  <div className="d-flex align-items-center justify-content-between">
                                    <Link
                                      to={assignment.file}
                                      target="_blank"
                                      className="btn btn-info text-white fw-600 text-uppercase font-xssss"
                                    >
                                      Download
                                    </Link>
                                    {submissions?.length > 0 &&
                                    submissions.some(
                                      submission =>
                                        submission.assignment_id ===
                                        assignment.id
                                    ) ? null : (
                                      <Link
                                        className="btn btn-success text-white fw-600 text-uppercase font-xssss float-right mr-1"
                                        onClick={() =>
                                          handleAssignmentSubmission(
                                            assignment.id
                                          )
                                        }
                                      >
                                        Submit Assignment
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <h6 className="text-center">
                              There are No Course Assignments added yet
                            </h6>
                          )}
                        </div>
                        {submissions?.length > 0 && (
                          <div className="card-body w-100 table-responsive">
                            <table className="table table-bordered data-table">
                              <thead>
                                <tr>
                                  <th>Submission ID</th>
                                  <th>Assignment</th>
                                  <th>File</th>
                                  <th>Feedback</th>
                                  <th>Grade</th>
                                  <th>Submitted At</th>
                                </tr>
                              </thead>
                              <tbody>
                                {submissions.map(submission => (
                                  <tr key={submission.id}>
                                    <td>{submission.id}</td>
                                    <td>{submission.assignment.title}</td>
                                    <td>
                                      <Link
                                        className="text-primary"
                                        to={submission.file}
                                        target="_blank"
                                      >
                                        Download
                                      </Link>
                                    </td>
                                    <td>
                                      {submission.feedback ||
                                        'Yet to be reviewed'}
                                    </td>
                                    <td>
                                      {submission.grade || 'Yet to be reviewed'}
                                    </td>
                                    <td>
                                      {new Date(
                                        submission.created_at
                                      ).toLocaleString()}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4">
              <div className="course__details__sidebar--2">
                <div className="event__sidebar__wraper" data-aos="fade-up">
                  <div className="course__summery__button">
                    {courseDetails.id &&
                    !studentCourses?.courses?.find(
                      course => course.id === courseDetails.id
                    ) ? (
                      <Link
                        onClick={handleCourseRegistration}
                        className="default__button"
                      >
                        Register now
                      </Link>
                    ) : (
                      <Link className="default__button bg-success">
                        <span className="text-white">Registered</span>
                      </Link>
                    )}
                  </div>
                  <div className="course__summery__lists">
                    <ul>
                      <li>
                        <div className="course__summery__item">
                          <span className="sb_label">Instructor:</span>
                          <span className="sb_content">
                            <Link to="instructor-details.html">
                              {courseDetails.instructor.name}
                            </Link>
                          </span>
                        </div>
                      </li>

                      <li>
                        <div className="course__summery__item">
                          <span className="sb_label">Total Duration</span>
                          <span className="sb_content">
                            {courseDetails.duration}
                          </span>
                        </div>
                      </li>

                      <li>
                        <div className="course__summery__item">
                          <span className="sb_label">Lectures</span>
                          <span className="sb_content">
                            {courseDetails.sections.length}
                          </span>
                        </div>
                      </li>

                      <li>
                        <div className="course__summery__item">
                          <span className="sb_label">Language</span>
                          <span className="sb_content">
                            {courseDetails?.languages?.length > 0 &&
                              JSON.parse(courseDetails?.languages).map(
                                el => `${el}, `
                              )}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <hr />
                  <div className="row">
                    <h5>Course Materials</h5>
                    {courseMaterials?.length > 0 ? (
                      courseMaterials.map(material => (
                        <div className="col-12 mb-3" key={material.id}>
                          <div className="border border-secondary p-2 d-flex align-items-center justify-content-between ">
                            <h6 className="fw-700 font-sm mb-0">
                              {material.name}
                            </h6>

                            <Link
                              to={material.file}
                              target="_blank"
                              className="btn btn-primary text-white font-sm text-uppercase"
                            >
                              Download
                            </Link>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-center mt-5">
                        {' '}
                        No materials added yet
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
