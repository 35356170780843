import { Link, useNavigate, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import axios from "axios";
import BASE_URL from "../../../config";
import { useState } from "react";

export default function Forgot() {
  const authToken = localStorage.getItem("authToken");
  const userRole = localStorage.getItem("userRole");

  const navigate = useNavigate();
  const baseUrl = BASE_URL;

  const [email, setEmail] = useState("");

  let isError = false;
  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!email.length) {
      fieldName.push("Email");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error("Email is Invalid!");
      isError = true;
    }

    if (isError) {
      return;
    }
    forgotPassword();
  };

  function forgotPassword() {
    axios
      .post(
        baseUrl + "/forgot-password",
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response.data?.message);
        navigate("/auth/login", { replace: true });
      })
      .catch(function (error) {
        console.log(error);
        toast.error(`${error.response?.data?.message}`);
      });
  }

  return authToken && userRole ? (
    <Navigate to={"/dashboard/" + userRole} />
  ) : (
    <div className="loginarea sp_top_100 sp_bottom_100">
      <div className="container">
        <div className="row">
          <div
            className="col-xl-8 col-md-8 offset-md-2"
            data-aos="fade-up"
          ></div>
          <div
            className="tab-content tab__content__wrapper"
            id="myTabContent"
            data-aos="fade-up"
          >
            <div className="col-xl-6 col-md-6 offset-md-3">
              <div className="loginarea__wraper">
                <div className="login__heading">
                  <h5 className="login__title">Forgot your Password</h5>
                </div>
                <form action="#">
                  <div className="login__form">
                    <label className="form__label">Email</label>
                    <input
                      className="common__login__input"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                  <div className="login__button">
                    <Link
                      onClick={(e) => handleSubmit(e)}
                      className="default__button"
                    >
                      Send Password Reset Link
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className=" login__shape__img educationarea__shape_image">
          <img
            className="hero__shape hero__shape__1"
            src="/img/education/hero_shape2.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__2"
            src="/img/education/hero_shape3.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__3"
            src="/img/education/hero_shape4.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__4"
            src="/img/education/hero_shape5.png"
            alt="Shape"
          />
        </div>
      </div>
    </div>
  );
}
