import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function Analytics(props) {
  let isError = false;
  const [id, setId] = useState(1);
  const [reports, setReports] = useState([]);
  const [reportName, setReportName] = useState("");
  const [reportType, setReportType] = useState("");
  const [studentId, setStudentId] = useState("");
  const [instructorId, setInstructorId] = useState("");
  const [coordianatorId, setCoordinatorId] = useState("");
  const [qaOfficerId, setQaOfficerId] = useState("");

  const getUserRole = () => {
    return localStorage.getItem("userRole");
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!reportName.length) {
      fieldName.push("Report Name");
    }
    if (!reportType.length) {
      fieldName.push("Report Type");
    }
    if (!studentId.length) {
      fieldName.push("Student ID");
    }
    if (!instructorId.length) {
      fieldName.push("Instructor ID");
    }
    if (!coordianatorId.length) {
      fieldName.push("Coordinator ID");
    }
    if (!qaOfficerId.length && userRole === "admin") {
      fieldName.push("QA Officer ID");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (isError) {
      return;
    }
    generateReport();
  };
  function convertToCSV(report) {
    const rows = [];
    const headers = Object.keys(report);
    rows.push(headers.map((header) => `"${header}"`).join(","));

    const values = headers.map((header) => report[header]);
    rows.push(values.map((value) => `"${value}"`).join(","));

    return rows.join("\n");
  }
  function downloadCSV(csv, fileName) {
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const clearFields = () => {
    setReportName("");
    setReportType("");
    setStudentId("");
    setCoordinatorId("");
    setQaOfficerId("");
    setInstructorId("");
  };
  const userRole = getUserRole();
  const generateReport = () => {
    const newReport = {
      id: id,
      name: reportName,
      type: reportType,
      studentId: studentId,
      coordinatorId: coordianatorId,
      qaOfficerId: qaOfficerId,
      instructorId: instructorId,
    };

    setReports((prevReports) => [...prevReports, newReport]);

    clearFields();
    setId(id + 1);
  };

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Reports & Performance</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Report Name</label>
                  <input
                    type="text"
                    onChange={(e) => setReportName(e.target.value)}
                    value={reportName}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Report Type</label>
                  <input
                    type="text"
                    onChange={(e) => setReportType(e.target.value)}
                    value={reportType}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Student ID</label>
                  <input
                    type="text"
                    onChange={(e) => setStudentId(e.target.value)}
                    value={studentId}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Instructor ID</label>
                  <input
                    type="text"
                    onChange={(e) => setInstructorId(e.target.value)}
                    value={instructorId}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Coordinator ID</label>
                  <input
                    type="text"
                    onChange={(e) => setCoordinatorId(e.target.value)}
                    value={coordianatorId}
                  />
                </div>
              </div>
            </div>
            {userRole === "admin" && (
              <div className="col-xl-6">
                <div className="dashboard__form__wraper">
                  <div className="dashboard__form__input">
                    <label>QA Officer ID</label>
                    <input
                      type="text"
                      onChange={(e) => setQaOfficerId(e.target.value)}
                      value={qaOfficerId}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="col-xl-12">
              <div className="dashboard__form__button pull-right">
                <Link
                  className="default__button"
                  onClick={(e) => handleSubmit(e)}
                >
                  Generate Report
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {reports.length > 0 && (
        <div className="row">
          <div className="col-xl-12 mt-5">
            <div className="dashboard__table table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Report ID</th>
                    <th>Report Name</th>
                    <th>Report Type</th>
                    <th>Student ID</th>
                    <th>Instructor ID</th>
                    <th>Coordinator ID</th>
                    {userRole === "admin" && <th>QA Officer ID</th>}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.map((report) => (
                    <tr key={report.id}>
                      <td>
                        <p>{report.id}</p>
                      </td>
                      <td>
                        <p>{report.name}</p>
                      </td>
                      <td>
                        <p>{report.type}</p>
                      </td>
                      <td>
                        <p>{report.studentId}</p>
                      </td>
                      <td>
                        <p>{report.instructorId}</p>
                      </td>
                      <td>
                        <p>{report.coordinatorId}</p>
                      </td>
                      {userRole === "admin" && (
                        <td>
                          <p>{report.qaOfficerId}</p>
                        </td>
                      )}
                      <td>
                        <div className="dashboard__button__group">
                          <Link
                            className="dashboard__small__btn__2"
                            onClick={() => {
                              const csvData = convertToCSV(report);
                              downloadCSV(csvData, `report_${report.id}.csv`);
                            }}
                          >
                            Download
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Analytics;
