import { createContext, useState, useEffect } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    userRole: localStorage.getItem("userRole"),
    authToken: localStorage.getItem("authToken"),
  });

  useEffect(() => {
    const storedRole = localStorage.getItem("userRole");
    const storedToken = localStorage.getItem("authToken");
    if (storedRole && storedToken) {
      setAuth({ userRole: storedRole, authToken: storedToken });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
