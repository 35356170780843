import axios from "axios";
import { useEffect, useState } from "react";
import BASE_URL from "../../../config";

function Profile() {
  const authToken = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const [user, setUser] = useState({});

  useEffect(() => {
    if (!Object.keys(user).length) {
      axios.get(BASE_URL + "/user", config).then((response) => {
        setUser(response.data);
      });
    }
  });

  function formatDate(date) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    return new Date(date).toLocaleString(undefined, options);
  }

  return (
    <div className="col-xl-9 col-lg-9 col-md-12">
      <div className="dashboard__content__wraper">
        <div className="dashboard__section__title">
          <h4>My Profile</h4>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="dashboard__form">Registration Date</div>
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="dashboard__form">{formatDate(user.created_at)}</div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="dashboard__form dashboard__form__margin">Name</div>
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="dashboard__form dashboard__form__margin">
              {user.name}
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="dashboard__form dashboard__form__margin">Email</div>
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="dashboard__form dashboard__form__margin">
              {user.email}
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="dashboard__form dashboard__form__margin">Phone</div>
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="dashboard__form dashboard__form__margin">
              {user.phone}
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="dashboard__form dashboard__form__margin">Role</div>
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="dashboard__form dashboard__form__margin">
              {user.role}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
