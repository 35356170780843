import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'

import axios from 'axios'
import { toast } from 'react-toastify'
import BASE_URL from '../../../config'

const grades = [
  { value: 'A+', label: 'A+' },
  { value: 'A', label: 'A' },
  { value: 'A-', label: 'A-' },
  { value: 'B+', label: 'B+' },
  { value: 'B', label: 'B' },
  { value: 'B-', label: 'B-' },
  { value: 'C+', label: 'C+' },
  { value: 'C', label: 'C' },
  { value: 'C-', label: 'C-' },
  { value: 'D+', label: 'D+' },
  { value: 'D', label: 'D' },
  { value: 'D-', label: 'D-' },
  { value: 'F', label: 'F' },
]

function GradeStudents() {
  const baseUrl = BASE_URL
  const token = localStorage.getItem('authToken')

  const [exams, setExams] = useState([])
  const [exam, setExam] = useState(null)
  const [examScores, setExamScores] = useState([])

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  useEffect(() => {
    if (!exams.length) {
      getExams()
    }
  })

  const getExams = () => {
    axios
      .get(baseUrl + '/exams', config)
      .then(response => {
        const exm = []
        response.data.exams.forEach(exam => {
          exm.push({ value: exam.id, label: exam.name })
        })
        setExams(exm)
      })
      .catch(function (error) {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const handleExamChange = selectedValue => {
    setExam(selectedValue)
  }

  const handleFilterStudents = () => {
    config.params = {
      exam_id: exam,
    }

    axios
      .get(baseUrl + '/exam-scores', config)
      .then(response => {
        setExamScores(response.data.grades)
      })
      .catch(function (error) {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const handleGradeSelection = (grade, id) => {
    const formData = new FormData()
    formData.append('grade', grade)

    axios
      .post(baseUrl + '/exam-scores/' + id, formData, config)
      .then(response => {
        toast.success(response.data.message)
        handleFilterStudents()
      })
      .catch(function (error) {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4 className="">Grade Students</h4>
      </div>
      <div className="card-body  p-2 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="form-label">Exam</label>
                <Select
                  isSearchable={false}
                  options={exams}
                  value={exams.find(option => option.value === exam)}
                  onChange={e => (e ? handleExamChange(e.value) : '')}
                  placeholder="Select a Exam"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link className="btn btn-primary" onClick={handleFilterStudents}>
                Filter Students
              </Link>
            </div>
          </div>
        </form>
      </div>
      {examScores?.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Course Name</th>
                <th>Exam Name</th>
                <th>Score</th>
                <th>Assign Grade</th>
              </tr>
            </thead>
            <tbody>
              {examScores.map(score => (
                <tr key={score.id}>
                  <td>{score.student.name}</td>
                  <td>{score.course.name}</td>
                  <td>{score.exam.name}</td>
                  <td>
                    {score.score +
                      ' / ' +
                      JSON.parse(score.exam.questions).length * 10}
                  </td>
                  <td className="text-center">
                    <select
                      className="form-control"
                      name="grades"
                      id="grades"
                      onChange={e =>
                        handleGradeSelection(e.target.value, score.id)
                      }
                      value={score.grade}
                    >
                      <option value="">Grade</option>
                      {grades.map(grade => (
                        <option key={grade.value} value={grade.value}>
                          {grade.label}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default GradeStudents
