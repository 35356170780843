import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import axios from 'axios'
import Select from 'react-select'
import BASE_URL from '../../../config'

function ManageAssignments() {
  const baseUrl = BASE_URL
  const token = localStorage.getItem('authToken')

  const [mode, setMode] = useState('add')
  const [courses, setCourses] = useState([])
  const [course, setCourse] = useState('')
  const [assignments, setAssignments] = useState([])
  const [assignment, setAssignment] = useState([])
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [due, setDue] = useState(new Date().toISOString().substr(0, 16))
  const [file, setFile] = useState(null)
  const [status, setStatus] = useState('')

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  useEffect(() => {
    if (!courses.length) {
      getCourses()
      getAssignments()
    }
  })

  const getCourses = () => {
    axios
      .get(baseUrl + '/courses', config)
      .then(response => {
        let crs = []
        for (const course of response.data.courses) {
          crs.push({ value: course.id, label: course.name })
        }
        setCourses(crs)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const getAssignments = () => {
    axios
      .get(baseUrl + '/assignments', config)
      .then(response => {
        setAssignments(response.data.assignments)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const clearFields = () => {
    setCourse('')
    setTitle('')
    setDescription('')
    setDue(new Date().toISOString().substr(0, 16))
    setFile(null)
    setStatus('')
  }

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ]

  const handleStatusChange = selectedOption => {
    setStatus(selectedOption.value)
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (mode === 'add') addAssignment()
    else updateAssignment()
  }

  const addAssignment = () => {
    const formData = new FormData()
    formData.append('course_id', course)
    formData.append('title', title)
    formData.append('description', description)
    formData.append('due', due)
    formData.append('file', file)
    formData.append('status', status)

    axios
      .post(baseUrl + '/assignment', formData, config)
      .then(response => {
        toast.success(response.data.message)
        getAssignments()
        clearFields()
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const updateAssignment = () => {
    const formData = new FormData()
    formData.append('course_id', course)
    formData.append('title', title)
    formData.append('description', description)
    formData.append('due', due)
    formData.append('status', status)

    axios
      .post(baseUrl + '/assignment/' + assignment.id, formData, config)
      .then(response => {
        toast.success(response.data.message)
        getAssignments()
        clearFields()
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const editAssignment = ass => {
    setMode('edit')
    setAssignment(ass)
    setCourse(ass.course_id)
    setTitle(ass.title)
    setDescription(ass.description)
    setDue(ass.due)
    setStatus(ass.status)
    setFile(ass.file)
  }

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        {' '}
        <h3 className="mb-0">Manage Assignments</h3>
      </div>

      <form>
        <div className="row">
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="form-label">Course</label>
              <Select
                isSearchable={false}
                options={courses}
                value={courses.find(option => option.value === course)}
                onChange={e => setCourse(e.value)}
                placeholder="Select a Course"
              />
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="form-label">Assignment Title</label>
              <input
                type="text"
                className="form-control"
                onChange={e => setTitle(e.target.value)}
                value={title}
              />
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="form-label">Due Date</label>
              <input
                type="datetime-local"
                className="form-control"
                onChange={e => setDue(e.target.value)}
                value={due}
              />
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="form-label">Status</label>
              <Select
                options={statusOptions}
                value={
                  statusOptions.find(option => option.value === status) || ''
                }
                onChange={handleStatusChange}
                placeholder="Course Status"
              />
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="form-label">Description</label>
              <textarea
                className="w-100 h100 style2-textarea p-3 form-control"
                onChange={e => setDescription(e.target.value)}
                value={description}
              />
            </div>
          </div>
          {mode === 'add' && (
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="form-label">
                  Upload File{' '}
                  <small className="text-danger">(File Size {'<='} 2MB)</small>
                  <br />
                  <small className="font-xssss text-grey-500">
                    (File Types Supported : pdf, doc, docx)
                  </small>
                </label>
                <input
                  type="file"
                  name="material-file"
                  accept=".pdf, .doc, .docx"
                  onChange={e => setFile(e.target.files[0])}
                />
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-lg-12 text-right">
            <button
              type="button"
              className="btn btn-primary mt-3 mb-0"
              onClick={handleSubmit}
            >
              {mode === 'add' ? 'Add' : 'Update'} Assignment
            </button>
          </div>
        </div>
      </form>
      {assignments.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="mt-5 table table-bordered data-table">
            <thead>
              <tr>
                <th>Assignment ID</th>
                <th>Course Name</th>
                <th>Assignment Title</th>
                <th>Description</th>
                <th>Due Date</th>
                <th>File</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {assignments.map(assign => (
                <tr key={assign.id}>
                  <td>{assign.id}</td>
                  <td>{assign.course.name}</td>
                  <td>{assign.title}</td>
                  <td>{assign.description}</td>
                  <td>{new Date(assign.due).toLocaleString()}</td>
                  <td>
                    <Link
                      className="text-primary"
                      to={assign.file}
                      target="_blank"
                    >
                      Download
                    </Link>
                  </td>
                  <td className="text-capitalize">{assign.status}</td>

                  <td className="text-center">
                    <Link
                      className="text-primary"
                      onClick={() => editAssignment(assign)}
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default ManageAssignments
