import { useState } from 'react'
import { Link } from 'react-router-dom'
import BASE_URL from '../../../config'
import axios from 'axios'
import { toast } from 'react-toastify'

function Settings() {
  const baseUrl = BASE_URL
  const token = localStorage.getItem('authToken')
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  }

  const handleSubmit = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('name', user.name)
    formData.append('email', user.email)
    formData.append('phone', user.phone)
    formData.append('role', user.role)
    formData.append('address', user.address)

    if (user.image instanceof File) {
      formData.append('image', user.image)
    }

    axios
      .post(baseUrl + '/user/' + user.id, formData, config)
      .then(response => {
        localStorage.setItem('user', JSON.stringify(response.data))
        toast.success('User Updated Successfully!')
        setUser(response.data)
      })
      .catch(function (error) {
        const tempdata = error.response.data

        if (tempdata.errors) {
          const errorKeys = Object.keys(tempdata.errors)
          for (const errorKey of errorKeys) {
            for (const error of tempdata.errors[errorKey]) {
              toast.error(`${error}`)
            }
          }
        }
      })
  }

  const handleChange = event => {
    const { name, value } = event.target
    setUser({ ...user, [name]: value })
  }

  const [password, setPassword] = useState({
    old: '',
    new: '',
    confirm: '',
  })

  const handleSubmitPassword = async e => {
    e.preventDefault()

    if (password.new !== password.confirm) {
      toast.error('Passwords do not match')
      return
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    }

    axios
      .post(
        baseUrl + '/change-password',
        {
          current_password: password.old,
          new_password: password.new,
        },
        {
          headers,
        }
      )
      .then(response => {
        toast.success(response.data.message)
        clearFields()
      })
      .catch(function (error) {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const clearFields = () => {
    setPassword({
      old: '',
      new: '',
      confirm: '',
    })
  }

  const handleChangePasswords = event => {
    const { name, value } = event.target
    setPassword({ ...password, [name]: value })
  }

  return (
    <div className="col-xl-9 col-lg-9 col-md-12">
      <div className="dashboard__content__wraper">
        <div className="dashboard__section__title">
          <h4>My Profile</h4>
        </div>
        <div className="row">
          <div className="col-xl-12 aos-init aos-animate" data-aos="fade-up">
            <ul
              className="nav  about__button__wrap dashboard__button__wrap"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="single__tab__link active"
                  data-bs-toggle="tab"
                  data-bs-target="#projects__one"
                  type="button"
                  aria-selected="true"
                  role="tab"
                >
                  Profile
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="single__tab__link"
                  data-bs-toggle="tab"
                  data-bs-target="#projects__two"
                  type="button"
                  aria-selected="false"
                  role="tab"
                  tabIndex={-1}
                >
                  Password
                </button>
              </li>
            </ul>
          </div>
          <div
            className="tab-content tab__content__wrapper aos-init aos-animate"
            id="myTabContent"
            data-aos="fade-up"
          >
            <div
              className="tab-pane fade active show"
              id="projects__one"
              role="tabpanel"
              aria-labelledby="projects__one"
            >
              <div className="row">
                <div className="col-xl-12">
                  <div className="dashboard__form__input">
                    <label className="mont-font fw-600 font-xsss">
                      Profile Picture (Optional)
                    </label>
                    {user.image && (
                      <figure
                        style={{
                          height: '100px',
                          width: '100px',
                          marginBottom: '30px',
                        }}
                      >
                        <img
                          src={
                            user.image instanceof File
                              ? URL.createObjectURL(user.image)
                              : user.image
                          }
                          alt="Profile"
                          className="shadow-sm rounded-lg w-100"
                        />
                      </figure>
                    )}

                    <div class="mb-3 col-4">
                      <input
                        class="form-control"
                        type="file"
                        id="formFileMultiple"
                        onChange={e =>
                          setUser({ ...user, image: e.target.files[0] })
                        }
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-6">
                      <div className="dashboard__form__wraper">
                        <div className="dashboard__form__input">
                          <label>Full Name</label>
                          <input
                            name={'name'}
                            type="text"
                            value={user.name}
                            onChange={handleChange}
                            placeholder="Enter full name"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="dashboard__form__wraper">
                        <div className="dashboard__form__input">
                          <label>Email</label>
                          <input
                            className="bg-light"
                            name="email"
                            type="email"
                            disabled
                            onChange={handleChange}
                            value={user.email}
                            placeholder="Enter email"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="dashboard__form__wraper">
                        <div className="dashboard__form__input">
                          <label>Phone Number</label>
                          <input
                            name="phone"
                            onChange={handleChange}
                            type="text"
                            value={user.phone}
                            placeholder="Enter Phone"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="dashboard__form__wraper">
                        <div className="dashboard__form__input">
                          <label>Role</label>
                          <input
                            disabled
                            className="bg-light"
                            name="role"
                            onChange={handleChange}
                            type="text"
                            value={user.role}
                            placeholder="Enter Role"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-12">
                      <div className="dashboard__form__wraper">
                        <div className="dashboard__form__input">
                          <label>Address</label>
                          <textarea
                            value={user.address}
                            name="address"
                            id="bio__text"
                            onChange={handleChange}
                            cols={30}
                            rows={10}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-12">
                      <div className="dashboard__form__button">
                        <Link
                          onClick={handleSubmit}
                          className="default__button"
                        >
                          Update Info
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="projects__two"
              role="tabpanel"
              aria-labelledby="projects__two"
            >
              <div className="row">
                <div className="col-xl-12">
                  <div className="dashboard__form__wraper">
                    <div className="dashboard__form__input">
                      <label>Current Password</label>
                      <input
                        name="old"
                        value={password.old}
                        onChange={handleChangePasswords}
                        type="password"
                        placeholder="Current password"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="dashboard__form__wraper">
                    <div className="dashboard__form__input">
                      <label>New Password</label>
                      <input
                        name="new"
                        value={password.new}
                        onChange={handleChangePasswords}
                        type="password"
                        placeholder="New Password"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="dashboard__form__wraper">
                    <div className="dashboard__form__input">
                      <label>Re-Type New Password</label>
                      <input
                        name="confirm"
                        value={password.confirm}
                        onChange={handleChangePasswords}
                        type="password"
                        placeholder="Re-Type New Password"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-12">
                  <div
                    className="dashboard__form__button"
                    onClick={handleSubmitPassword}
                  >
                    <Link className="default__button">Update Password</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
