import { Link } from 'react-router-dom'

const userRole = 'qa'
export default function DashContent() {
  return (
    <div className="col-xl-9 col-lg-9 col-md-12">
      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__section__title">
              <h4>QA Officers</h4>
            </div>
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link
                      to={'/dashboard/' + userRole + '/curriculum-evaluation'}
                    >
                      Curriculum Evalutation
                    </Link>{' '}
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={'/dashboard/' + userRole + '/qs-compliance'}>
                      {' '}
                      QS Complaince
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link
                      to={'/dashboard/' + userRole + '/faculty-development'}
                    >
                      {' '}
                      Faculty development
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link
                      to={'/dashboard/' + userRole + '/improvement-initiatives'}
                    >
                      {' '}
                      Improvement initiatives
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link
                      to={'/dashboard/' + userRole + '/support-and-enquiries'}
                    >
                      Support & Enquiries
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to={'/dashboard/' + userRole + '/reports'}>
                      Reports & Performance
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
