import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import BASE_URL from "../../../config";
import { useEffect, useRef } from "react";

export default function VerifyEmail() {
  const authToken = localStorage.getItem("authToken");
  const baseUrl = BASE_URL;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const url = searchParams.get("url");
  const expires = searchParams.get("expires");
  const signature = searchParams.get("signature");

  let verified = useRef(false);
  useEffect(() => {
    const apiCall = async () => {
      const parseUrl = baseUrl + "/email/verify" + url;
      const headers = {
        Authorization: "Bearer " + authToken,
      };

      const verifyUrl = `${parseUrl}?expires=${expires}&signature=${signature}`;

      try {
        const response = await axios.get(verifyUrl, {
          headers,
        });
        toast.success(response.data.message);
      } catch (error) {
        toast.error(error);
      }
    };
    if (!verified.current) {
      apiCall();
      verified.current = true;
    }
  });

  return (
    <div className="loginarea sp_top_100 sp_bottom_100">
      <div className="container">
        <div className="row">
          <div
            className="col-xl-8 col-md-8 offset-md-2"
            data-aos="fade-up"
          ></div>
          <div
            className="tab-content tab__content__wrapper"
            id="myTabContent"
            data-aos="fade-up"
          >
            <div className="col-xl-8 col-md-8 offset-md-2">
              <div className="loginarea__wraper">
                <div className="login__heading">
                  <h5 className="login__title">Email Verified!</h5>
                  <p className="login__description">
                    Your email has been verified successfully.
                    <br />
                    Please <Link to="/auth/login">click here</Link> to login
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" login__shape__img educationarea__shape_image">
          <img
            className="hero__shape hero__shape__1"
            src="/img/education/hero_shape2.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__2"
            src="/img/education/hero_shape3.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__3"
            src="/img/education/hero_shape4.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__4"
            src="/img/education/hero_shape5.png"
            alt="Shape"
          />
        </div>
      </div>
    </div>
  );
}
