import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import BASE_URL from '../../../config'

function ManageCourses(props) {
  const baseUrl = BASE_URL
  const token = localStorage.getItem('authToken')

  const [activeId, setActiveId] = useState(-1)
  const [students, setStudents] = useState([])
  const [student, setStudent] = useState(null)
  const [courses, setCourses] = useState([])
  const [studentCourses, setStudentCourses] = useState([])

  const [selectedCourses, setSelectedCourses] = useState([])
  let isError = false
  const [mode, setMode] = useState('add')

  const title = props.title
  const role = props.role
  const dis = props.dis

  useEffect(() => {
    if (!students.length) {
      getStudents()
      getCourses()
      getStudentCourses()
    }
  })

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const getStudents = () => {
    axios
      .get(baseUrl + '/role-users', {
        ...config,
        params: {
          role: 'student',
        },
      })
      .then(response => {
        const studs = []
        response.data.users.forEach(student => {
          studs.push({ value: student.id, label: student.name })
        })
        setStudents(studs)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const getCourses = () => {
    axios
      .get(baseUrl + '/courses', config)
      .then(response => {
        const cours = []
        response.data.courses.forEach(course => {
          cours.push({ value: course.id, label: course.name })
        })
        setCourses(cours)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const getStudentCourses = () => {
    axios
      .get(baseUrl + '/admin/student-courses', config)
      .then(response => {
        setStudentCourses(response.data.studentCourses)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const handleStudentChange = selectedValues => {
    setStudent(selectedValues)
  }

  const handleCourseSelection = selectedValues => {
    setSelectedCourses(selectedValues)
  }

  const handleSubmit = e => {
    e.preventDefault()
    let fieldName = []
    if (!student) {
      toast.error('Please select a Student!')
      return
    }
    if (selectedCourses.length === 0) {
      isError = true
      fieldName.push('Courses Selection')
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + ' is Required!')
      }
      isError = true
    }
    if (isError) {
      return
    }
    if (mode === 'add') {
      addStudentCourse()
    } else {
      updateStudentCourse()
    }
  }

  const clearFields = () => {
    setStudent('')
    setSelectedCourses([])
    setMode('add')
  }

  const addStudentCourse = () => {
    const newStudent = {
      student_id: student,
      courses: selectedCourses.map(course => course.value),
    }
    axios
      .post(baseUrl + '/admin/student-courses', newStudent, config)
      .then(response => {
        toast.success(response.data.message)
        getStudentCourses()
      })
    clearFields()
  }

  const editStudentCourse = id => {
    setMode('edit')
    setActiveId(id)
    const studentCourse = studentCourses.find(student => student.id === id)
    setStudent(studentCourse.student_id)
    setSelectedCourses(
      studentCourse.courses.map(course => {
        return { value: course.id, label: course.name }
      })
    )
  }

  const deleteStudentCourse = id => {
    axios
      .delete(baseUrl + '/admin/student-courses/' + id, config)
      .then(response => {
        toast.success(response.data.message)
        getStudentCourses()
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
    clearFields()
  }

  const updateStudentCourse = () => {
    const newStudent = {
      student_id: student,
      courses: selectedCourses.map(course => course.value),
    }
    axios
      .post(baseUrl + '/admin/student-courses/' + activeId, newStudent, config)
      .then(response => {
        toast.success(response.data.message)
        getStudentCourses()
      })
    clearFields()
  }

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>{title}</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>{role}</label>
                  <Select
                    options={students}
                    value={
                      student
                        ? students.find(option => option.value === student)
                        : ''
                    }
                    onChange={e => (e ? handleStudentChange(e.value) : '')}
                    placeholder="Select a Student"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Courses</label>
                  <Select
                    isMulti
                    options={courses}
                    onChange={handleCourseSelection}
                    value={selectedCourses}
                    placeholder="Select Courses"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-12 mt-4">
              <div className="dashboard__form__button pull-right">
                <Link
                  className="default__button"
                  onClick={e => handleSubmit(e)}
                >
                  {mode === 'add' ? 'Add' : 'Edit'} {dis}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {studentCourses.length > 0 && (
        <div className="row">
          <div className="col-xl-12 mt-5">
            <div className="dashboard__table table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>{role} ID</th>
                    <th>Name</th>
                    <th>Courses Registered</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {studentCourses.map(student => (
                    <tr key={student.id}>
                      <td>
                        <p>{student.id}</p>
                      </td>
                      <td>
                        <p>{student.student.name}</p>
                      </td>
                      <td>
                        <p>
                          {student.courses
                            .map(course => course.name)
                            .join(', ')}
                        </p>
                      </td>
                      <td>
                        <div className="dashboard__button__group">
                          <Link
                            className="dashboard__small__btn__2"
                            onClick={() => editStudentCourse(student.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit"
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                            </svg>
                            &nbsp; Edit
                          </Link>
                          <Link
                            className="dashboard__small__btn__2 dashboard__small__btn__3"
                            onClick={() => deleteStudentCourse(student.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6" />
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                              <line x1={10} y1={11} x2={10} y2={17} />
                              <line x1={14} y1={11} x2={14} y2={17} />
                            </svg>
                            &nbsp; Delete
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ManageCourses
