import { Link } from 'react-router-dom'
import BASE_URL from '../../../config'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function CourseCatalog() {
  const baseUrl = BASE_URL
  const token = localStorage.getItem('authToken')

  const [courses, setCourses] = useState([])

  useEffect(() => {
    if (!courses.length) {
      getCourses()
    }
  })

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const getCourses = () => {
    axios
      .get(baseUrl + '/courses', config)
      .then(response => {
        setCourses(response.data.courses)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  return (
    <div className="col-xl-9 col-lg-9 col-md-12">
      <div className="dashboard__content__wraper">
        <div className="dashboard__section__title">
          <h4>All Courses</h4>
        </div>
        <div className="row">
          {courses.length
            ? courses.map(
                el =>
                  el.status === 'active' && (
                    <div
                      className="col-xl-4 col-lg-6 col-md-6 col-12"
                      key={el.id}
                    >
                      <div className="gridarea__wraper">
                        <div className="gridarea__content">
                          <div className="gridarea__list">
                            <ul>
                              <li>
                                <i className="icofont-book-alt"></i>{' '}
                                {el.sections?.length} Lesson
                              </li>
                              <li>
                                <i className="icofont-clock-time"></i>{' '}
                                {el.duration}
                              </li>
                            </ul>
                          </div>
                          <div className="gridarea__heading">
                            <h3>
                              <Link
                                to={`/dashboard/student/course-details/${el.id}`}
                              >
                                {el.name}
                              </Link>
                            </h3>
                          </div>

                          <div className="gridarea__bottom">
                            <Link href="instructor-details.html">
                              <div className="gridarea__small__img">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M15.71 12.71a6 6 0 1 0-7.42 0a10 10 0 0 0-6.22 8.18a1 1 0 0 0 2 .22a8 8 0 0 1 15.9 0a1 1 0 0 0 1 .89h.11a1 1 0 0 0 .88-1.1a10 10 0 0 0-6.25-8.19ZM12 12a4 4 0 1 1 4-4a4 4 0 0 1-4 4Z"
                                  />
                                </svg>

                                <div className="gridarea__small__content">
                                  <h6>{el.instructor.name}</h6>
                                </div>
                              </div>
                            </Link>

                            <div className="gridarea__star">
                              <i className="icofont-star"></i>
                              <i className="icofont-star"></i>
                              <i className="icofont-star"></i>
                              <i className="icofont-star"></i>
                              <i className="icofont-star"></i>
                              <span>{el.rating}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )
            : 'No Courses found!'}
        </div>
      </div>
    </div>
  )
}
