import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Layout from './layout/Layout'
import Home from './pages/Home'
import About from './pages/About'
import Courses from './pages/Courses'
import CourseDetails from './pages/CourseDetails'
import Login from './pages/Auth/Login'
import SignUp from './pages/Auth/SignUp'
import Admin from './pages/Dashboard/Admin'
import DashboardLayout from './layout/Dashboard/DashboardLayout'
import Contact from './pages/Contact'
import Profile from './layout/Dashboard/Profile'
import Message from './layout/Dashboard/Message'
import CourseStatus from './layout/Dashboard/Courses'
import Reviews from './layout/Dashboard/Reviews'
import Quiz from './layout/Dashboard/Quiz'
import Settings from './layout/Dashboard/Settings'
import Blog from './pages/Blog'
import Instructor from './pages/Dashboard/Instructor'
import Student from './pages/Dashboard/Student'
import QA from './pages/Dashboard/QA'
import { AuthProvider } from '../context/authProvider'
import Auth from './Auth'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AdminActions from './pages/Dashboard/AdminActions'
import ManageUsers from './pages/Dashboard/ManageUsers'
import ManageCourses from './pages/Dashboard/ManageCourses'
import AssignStudents from './pages/Dashboard/AssignStudents'
import ReportsAndPerformance from './pages/Dashboard/ReportsAndPerformance'
import Analytics from './pages/Dashboard/Analytics'
import Forgot from './pages/Auth/Forgot'
import VerifyEmail from './pages/Auth/VerifyEmail'
import Reset from './pages/Auth/Reset'
import InstructorActions from './pages/Dashboard/InstructorActions'
import CourseContent from './pages/Dashboard/CourseContent'
import GradeStudent from './pages/Dashboard/GradeStudent'
import CoordinatorActions from './pages/Dashboard/CoordinatorActions'
import ProgramImplementation from './pages/Dashboard/ProgramImplementation'
import SupportAndEnquiries from './pages/Dashboard/SupportAndEnquiries'
import StudentPerformanceData from './pages/Dashboard/StudentPerformanceData'
import CreateCourses from './pages/Dashboard/CreateCourses'
import CreateExam from './pages/Dashboard/CreateExam'
import ListExams from './pages/Dashboard/ListExams'
import ExamQuestions from './pages/Dashboard/ExamQuestions'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from '../redux/store'
import CourseCatalog from './pages/Dashboard/CourseCatalog'
import CurriculumDevelopment from './pages/Dashboard/CurriculumDevelopment'
import ProgramEvaluation from './pages/Dashboard/ProgramEvaluation'
import CourseMaterials from './pages/Dashboard/CourseMaterials'
import ManageAssignments from './pages/Dashboard/ManageAssignments'
import ManageSubmissions from './pages/Dashboard/ManageSubmissions'
import StudentProgress from './pages/Dashboard/StudentProgress'
import ImprovementInitiatives from './pages/Dashboard/ImprovementInitiatives'
import QSCompliance from './pages/Dashboard/QSCompliance'
import FacultyDevelopment from './pages/Dashboard/FacultyDevelopment'

function Redirect() {
  return <Navigate to="/" />
}

function RedirectRoot({ link }) {
  return <Navigate to={link} />
}

function App() {
  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="blog" element={<Blog />} />
              <Route path="contact" element={<Contact />} />
              <Route path="courses" element={<Courses />} />
              <Route path="course-details" element={<CourseDetails />} />
              <Route path="auth/login" element={<Login />} />
              <Route path="auth/sign-up" element={<SignUp />} />
              <Route path="auth/verify-email" element={<VerifyEmail />} />
              <Route path="auth/forgot-your-password" element={<Forgot />} />
              <Route path="auth/reset-password" element={<Reset />} />
            </Route>
            <Route
              element={
                <Auth
                  allowedRoles={[
                    'admin',
                    'instructor',
                    'coordinator',
                    'qa',
                    'student',
                  ]}
                />
              }
            >
              <Route path="/dashboard" element={<DashboardLayout />}>
                <Route element={<Auth allowedRoles={['admin']} />}>
                  <Route path="admin" element={<Admin />}>
                    <Route index element={<AdminActions />} />
                    <Route
                      path="manage-students"
                      element={<ManageUsers role="Student" />}
                    />
                    <Route
                      path="manage-courses"
                      element={
                        <ManageCourses
                          heading="Manage Courses"
                          role="Student"
                          action="Courses"
                        />
                      }
                    />
                    <Route
                      path="reports-and-performance"
                      element={<ReportsAndPerformance />}
                    />
                    <Route
                      path="manage-qa-officers"
                      element={<ManageUsers role="QA Officer" />}
                    />
                    <Route
                      path="assign-course"
                      element={
                        <ManageCourses
                          heading="Assign Course"
                          role="QA Officer"
                          action="Courses"
                        />
                      }
                    />
                    <Route
                      path="manage-instructors"
                      element={<ManageUsers role="Instructor" />}
                    />
                    <Route path="handle-courses" element={<CreateCourses />} />
                    <Route path="course-content" element={<CourseContent />} />
                    <Route path="handle-exams" element={<CreateExam />} />
                    <Route
                      path="course-materials"
                      element={<CourseMaterials />}
                    />
                    <Route
                      path="manage-assignments"
                      element={<ManageAssignments />}
                    />
                    <Route
                      path="assign-students"
                      element={<AssignStudents />}
                    />
                    <Route path="reviews" element={<Reviews />} />
                    <Route
                      path="manage-coordinators"
                      element={<ManageUsers role="Coordinator" />}
                    />
                    <Route
                      path="handle-programs"
                      element={<ProgramImplementation />}
                    />
                    <Route
                      path="assign-support-tickets"
                      element={<SupportAndEnquiries />}
                    />
                    <Route path="analytics" element={<Analytics />} />
                    <Route
                      path="improvement-initiatives"
                      element={<ImprovementInitiatives />}
                    />
                  </Route>
                </Route>
                <Route element={<Auth allowedRoles={['instructor']} />}>
                  <Route path="instructor" element={<Instructor />}>
                    <Route index element={<InstructorActions />} />
                    <Route
                      path="course-management"
                      element={<CreateCourses />}
                    />
                    <Route path="course-content" element={<CourseContent />} />
                    <Route
                      path="assessment-creation"
                      element={<CreateExam />}
                    />
                    <Route path="grade-student" element={<GradeStudent />} />
                    <Route
                      path="course-materials"
                      element={<CourseMaterials />}
                    />
                    <Route
                      path="manage-assignments"
                      element={<ManageAssignments />}
                    />
                    <Route
                      path="manage-submissions"
                      element={<ManageSubmissions />}
                    />
                  </Route>
                </Route>
                <Route element={<Auth allowedRoles={['coordinator']} />}>
                  <Route
                    path="coordinator"
                    element={<RedirectRoot link={'/dashboard/profile'} />}
                  />

                  <Route index element={<CoordinatorActions />} />
                  <Route
                    path="program-overview"
                    element={<ProgramImplementation />}
                  />
                  <Route
                    path="curriculum-development-updates"
                    element={<CurriculumDevelopment />}
                  />
                  <Route
                    path="program-evaluation"
                    element={<ProgramEvaluation />}
                  />
                  <Route
                    path="support-and-enquiries"
                    element={<SupportAndEnquiries />}
                  />
                  <Route
                    path="student-performance-data"
                    element={<StudentPerformanceData />}
                  />
                  <Route
                    path="reports"
                    element={
                      <div className="col-9">
                        {' '}
                        <ReportsAndPerformance />
                      </div>
                    }
                  />
                </Route>
                <Route element={<Auth allowedRoles={['qa']} />}>
                  <Route path="qa" element={<QA />} />
                  <Route
                    path="qa/curriculum-evaluation"
                    element={<CurriculumDevelopment />}
                  />
                  <Route path="qa/qs-compliance" element={<QSCompliance />} />
                  <Route
                    path="qa/faculty-development"
                    element={<FacultyDevelopment />}
                  />
                  <Route
                    path="qa/reports"
                    element={
                      <div className="col-9">
                        {' '}
                        <ReportsAndPerformance />
                      </div>
                    }
                  />
                  {/* <Route index element={<QAActions />} /> */}
                  <Route
                    index
                    path="qa/support-and-enquiries"
                    element={<SupportAndEnquiries />}
                  />
                  <Route
                    index
                    path="qa/improvement-initiatives"
                    element={
                      <div className="col-9">
                        {' '}
                        <ImprovementInitiatives />
                      </div>
                    }
                  />
                </Route>
                <Route element={<Auth allowedRoles={['student']} />}>
                  <Route path="student" element={<Student />} />
                  <Route path="stud-progress" element={<StudentProgress />} />
                  <Route
                    path="student/course-details/:courseId"
                    element={<CourseDetails />}
                  />
                  <Route path="course-catalog" element={<CourseCatalog />} />
                  <Route path="exams" element={<ListExams />} />
                  <Route path="exams/:examId" element={<ExamQuestions />} />
                </Route>
                <Route path="profile" element={<Profile />} />
                <Route path="message" element={<Message />} />
                <Route path="course-status" element={<CourseStatus />} />
                <Route path="reviews" element={<Reviews />} />
                <Route path="quiz-attempts" element={<Quiz />} />
                <Route path="settings" element={<Settings />} />
              </Route>
            </Route>
            <Route path="*" element={<Redirect />} />
          </Routes>
          <ToastContainer />
        </AuthProvider>
      </BrowserRouter>
    </ReduxProvider>
  )
}

export default App
