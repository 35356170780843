import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import validator from 'validator'
import api from '../../../api'
import axios from 'axios'
import BASE_URL from '../../../config'

function ManageUsers(props) {
  const role = props.role
  const token = localStorage.getItem('authToken')
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      role: role.split(' ')[0].toString().toLowerCase(),
    },
  }

  let isError = false
  const [mode, setMode] = useState('add')
  const [id, setId] = useState(-1)
  const [users, setUsers] = useState([])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [image, setImage] = useState('')

  const handleSubmit = e => {
    e.preventDefault()

    let fieldName = []
    if (!name.length) {
      fieldName.push('Name')
    }
    if (!email.length) {
      fieldName.push('Email')
    }
    if (!phone.length) {
      fieldName.push('Phone')
    }
    if (!address.length) {
      fieldName.push('Address')
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + ' is Required!')
      }
      isError = true
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error('Email is Invalid!')
      isError = true
    }

    const phoneNumberValidation = /^[+]?\d+$/
    if (
      phone.length &&
      !validator.isMobilePhone(phone) &&
      !phoneNumberValidation.test(phone)
    ) {
      toast.error('Phone Number is Invalid!')
      isError = true
    }

    if (isError) {
      return
    }
    mode === 'add'
      ? addUser()
      : updateUser({
          name,
          id,
          email,
          address,
          phone,
          image,
        })
  }

  const clearFields = () => {
    setName('')
    setEmail('')
    setPhone('')
    setAddress('')
    setImage('')
  }

  const addUser = () => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('address', address)
    if (image instanceof File) {
      formData.append('image', image)
    }
    formData.append('role', role.split(' ')[0].toString().toLowerCase())

    axios
      .post(BASE_URL + '/user', formData, config)
      .then(response => {
        toast.success(role + ' Added Successfully!')
        fetchData()
        clearFields()
      })
      .catch(error => {
        console.log(error)
      })

    clearFields()
  }

  const editUser = id => {
    setMode('edit')
    const activeUser = users.find(user => user.id === id)
    setId(activeUser.id)
    setName(activeUser.name)
    setEmail(activeUser.email)
    setPhone(activeUser.phone)
    setAddress(activeUser.address)
    setImage(activeUser.image)
  }

  const changeUserStatus = id => {
    const activeUser = users.find(user => user.id === id)
    activeUser.is_active = !activeUser.is_active
    updateUser(activeUser)
  }

  const updateUser = activeUser => {
    const formData = new FormData()
    formData.append('name', activeUser.name)
    formData.append('email', activeUser.email)
    formData.append('phone', activeUser.phone)
    formData.append('address', activeUser.address)
    if (activeUser.image instanceof File) {
      formData.append('image', activeUser.image)
    }
    formData.append('is_active', activeUser.is_active)

    axios
      .post(BASE_URL + '/user/' + activeUser.id, formData, config)
      .then(response => {
        toast.success(role + ' Updated Successfully!')
        fetchData()
        clearFields()
        setMode('add')
      })
      .catch(function (error) {
        const tempdata = error.response.data

        if (tempdata.errors) {
          const errorKeys = Object.keys(tempdata.errors)
          for (const errorKey of errorKeys) {
            for (const error of tempdata.errors[errorKey]) {
              toast.error(`${error}`)
            }
          }
        }
      })
  }

  const fetchData = async () => {
    try {
      const response = await api({
        url: '/role-users',
        params: { role: role.split(' ')[0].toString().toLowerCase() },
      })
      setUsers(response.users)
    } catch (error) {
      console.error('Error fetching users list:', error)
    }
  }

  useEffect(() => {
    if (!users.length) fetchData()
  })

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Manage {role}s</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Name</label>
                  <input
                    type="text"
                    onChange={e => setName(e.target.value)}
                    value={name}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Email</label>
                  <input
                    type="email"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Phone</label>
                  <input
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    onChange={e => setPhone(e.target.value)}
                    value={phone}
                    maxLength={10}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Address</label>
                  <textarea
                    name="address"
                    id="address"
                    cols={30}
                    rows={3}
                    onChange={e => setAddress(e.target.value)}
                    value={address}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-12 mb-3">
              <div className="card border-0">
                <div className="card-body d-flex justify-content-between align-items-end p-0">
                  <div className="form-group mb-0 w-100">
                    <label className="mont-font fw-600 font-xsss">
                      Profile Picture (Optional)
                    </label>
                    {image && (
                      <figure>
                        <img
                          height={200}
                          src={
                            image instanceof File
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt="Profile"
                        />
                      </figure>
                    )}

                    <div class="mb-3 col-4">
                      <input
                        class="form-control"
                        type="file"
                        id="formFileMultiple"
                        onChange={e => setImage(e.target.files[0])}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="dashboard__form__button">
                <Link
                  className="default__button"
                  onClick={e => handleSubmit(e)}
                >
                  {mode === 'add' ? 'Add' : 'Edit'} {role}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {users.length > 0 && (
        <div className="row">
          <div className="col-xl-12 mt-5">
            <div className="dashboard__table table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>{role} ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Profile Picture</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(user => (
                    <tr key={user.id}>
                      <td>
                        <p>{user.id}</p>
                      </td>
                      <td>
                        <p>{user.name}</p>
                      </td>
                      <td>
                        <p>{user.email}</p>
                      </td>
                      <td>
                        <p>{user.phone}</p>
                      </td>
                      <td>
                        <p>{user.address}</p>
                      </td>
                      <td>
                        {user.image ? (
                          <figure
                            style={{
                              height: '100px',
                              width: '100px',
                              marginBottom: '30px',
                            }}
                          >
                            <img
                              src={
                                user.image instanceof File
                                  ? URL.createObjectURL(user.image)
                                  : user.image
                              }
                              alt="Profile"
                              className="shadow-sm rounded-lg w-100"
                            />
                          </figure>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>
                        <div className="dashboard__button__group">
                          <Link
                            className="dashboard__small__btn__2"
                            onClick={() => editUser(user.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit"
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                            </svg>
                            &nbsp; Edit
                          </Link>
                          <Link
                            className={`dashboard__small__btn__2 dashboard__small__btn__3`}
                            style={{
                              backgroundColor: user.is_active ? 'red' : 'green',
                              color: 'white',
                            }}
                            onClick={() => changeUserStatus(user.id)}
                          >
                            {user.is_active ? 'De-activate' : 'Activate'}
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ManageUsers
