import { Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import axios from "axios";
import BASE_URL from "../../../config";
import { useState } from "react";

export default function Reset() {
  const authToken = localStorage.getItem("authToken");
  const userRole = localStorage.getItem("userRole");

  const navigate = useNavigate();
  const baseUrl = BASE_URL;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  let isError = false;
  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!email.length) {
      fieldName.push("Email");
    }
    if (!password.length) {
      fieldName.push("Password");
    }
    if (!confirmPassword.length) {
      fieldName.push("Confirm Password");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error("Email is Invalid!");
      isError = true;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
      isError = true;
    }

    if (isError) {
      return;
    }
    resetPassword();
  };

  async function resetPassword() {
    const resetPasswordUrl = baseUrl + "/reset-password";
    const body = {
      email: email,
      password: password,
      password_confirmation: confirmPassword,
      token: token,
    };
    try {
      const response = await axios.post(resetPasswordUrl, body);
      toast.success(response.data.message);
      navigate("/auth/login", { replace: true });
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  }

  return authToken && userRole ? (
    <Navigate to={"/dashboard/" + userRole} />
  ) : (
    <div className="loginarea sp_top_100 sp_bottom_100">
      <div className="container">
        <div className="row">
          <div
            className="col-xl-8 col-md-8 offset-md-2"
            data-aos="fade-up"
          ></div>
          <div
            className="tab-content tab__content__wrapper"
            id="myTabContent"
            data-aos="fade-up"
          >
            <div className="col-xl-6 col-md-6 offset-md-3">
              <div className="loginarea__wraper">
                <div className="login__heading">
                  <h5 className="login__title">Reset Password</h5>
                </div>
                <form action="#">
                  <div className="login__form">
                    <label className="form__label">Email</label>
                    <input
                      className="common__login__input"
                      type="email"
                      placeholder="Enter your Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                  <div className="login__form">
                    <label className="form__label">New Password</label>
                    <input
                      className="common__login__input"
                      type="password"
                      placeholder="New Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </div>
                  <div className="login__form">
                    <label className="form__label">Confirm New Password</label>
                    <input
                      className="common__login__input"
                      type="password"
                      placeholder="Confirm New Password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                    />
                  </div>
                  <div className="login__button">
                    <Link
                      onClick={(e) => handleSubmit(e)}
                      className="default__button"
                    >
                      Reset Password
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className=" login__shape__img educationarea__shape_image">
          <img
            className="hero__shape hero__shape__1"
            src="/img/education/hero_shape2.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__2"
            src="/img/education/hero_shape3.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__3"
            src="/img/education/hero_shape4.png"
            alt="Shape"
          />
          <img
            className="hero__shape hero__shape__4"
            src="/img/education/hero_shape5.png"
            alt="Shape"
          />
        </div>
      </div>
    </div>
  );
}
