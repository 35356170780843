import { Link } from "react-router-dom";

function MobileMenu() {
  const authToken = localStorage.getItem("authToken");
  const userRole = localStorage.getItem("userRole");

  return (
    <div className="mobile-off-canvas-active">
      <Link className="mobile-aside-close">
        <i className="icofont icofont-close-line" />
      </Link>
      <div className="header-mobile-aside-wrap">
        <div className="mobile-menu-wrap headerarea">
          <div className="mobile-navigation">
            <nav>
              <ul className="mobile-menu">
                <li className="menu-item-has-children">
                  <Link to="/about">About Us</Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/courses">Courses</Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/blog">Blog</Link>
                </li>
                {authToken && userRole ? (
                  <div className="headerarea__login mt-2 mb-2">
                    <Link to={"/dashboard/" + userRole}>
                      <i className="icofont-user-alt-5" />
                      &nbsp;&nbsp;Dashboard
                    </Link>
                  </div>
                ) : (
                  <div>
                    <div className="headerarea__login mb-2">
                      <Link to="/auth/login">Login </Link>
                    </div>
                    <div className="headerarea__button">
                      <Link to="/auth/sign-up">Sign Up</Link>
                    </div>
                  </div>
                )}
              </ul>
            </nav>
          </div>
        </div>
        <div className="mobile-social-wrap">
          <Link className="facebook">
            <i className="icofont icofont-facebook" />
          </Link>
          <Link className="twitter">
            <i className="icofont icofont-twitter" />
          </Link>
          <Link className="pinterest">
            <i className="icofont icofont-pinterest" />
          </Link>
          <Link className="instagram">
            <i className="icofont icofont-instagram" />
          </Link>
          <Link className="google">
            <i className="icofont icofont-youtube-play" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
