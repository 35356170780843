import { Link } from 'react-router-dom'
import BASE_URL from '../../../config'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function DashContent() {
  const baseUrl = BASE_URL
  const token = localStorage.getItem('authToken')

  const [programs, setPrograms] = useState({})
  const [studentCourses, setStudentCourses] = useState([])

  useEffect(() => {
    if (!Object.keys(programs).length) {
      getStudentCourses()
      getPrograms()
    }

    if (!cgpaRank) {
      localStorage.setItem(
        'CGPA_RANK',
        (Math.random().toFixed(2) * 4 + 1).toFixed(2)
      )
    }
  })

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const getStudentCourses = () => {
    axios
      .get(baseUrl + '/admin/my-courses', config)
      .then(response => {
        const studCourses = response.data.myCourses
        if (studCourses && studCourses.courses?.length > 0) {
          for (let i = 0; i < studCourses.courses.length; i++) {
            studCourses.courses[i].completion = Math.round(
              Math.random().toFixed(2) * 100
            )
          }
        }
        setStudentCourses(studCourses)
      })
      .catch(error => {
        toast.error(`${error}`)
      })
  }

  const getPrograms = () => {
    axios
      .get(baseUrl + '/programs', config)
      .then(response => {
        if (response?.data?.programs?.length)
          setPrograms(response.data.programs[0])
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }
  const cgpaRank = localStorage.getItem('CGPA_RANK')

  return (
    <div className="col-xl-9 col-lg-9 col-md-12">
      <div className="col-12 p-5 card mb-4 shadow-sm border-0 ">
        <div className="dashboard__section__title">
          <h2 className="">Academic Program Overview</h2>
        </div>
        <h3 className=" mt-2">{programs.name}</h3>
        <p className="">{programs.description}</p>

        <h4 className=" mt-4">Curriculum</h4>
        <p className="">{programs.curriculum}</p>

        <h4 className="">Duration</h4>
        <p className="">{programs.duration}</p>

        <h4 className="">CGPA</h4>

        <p className="">{cgpaRank}</p>
      </div>

      <div className="dashboard__content__wraper">
        <div className="dashboard__section__title">
          <h4>My Courses</h4>
        </div>
        <div className="row">
          {studentCourses && Object.keys(studentCourses).length > 0 ? (
            studentCourses.courses?.map(
              el =>
                el.status === 'active' && (
                  <div
                    className="col-xl-4 col-lg-6 col-md-6 col-12"
                    key={el.id}
                  >
                    <div className="gridarea__wraper">
                      <div className="gridarea__content">
                        <div className="gridarea__list">
                          <ul>
                            <li>
                              <i className="icofont-book-alt"></i>{' '}
                              {el.sections?.length} Modules
                            </li>
                            <li className="text-right">
                              <i className="icofont-clock-time"></i>{' '}
                              {el.duration}
                            </li>
                          </ul>
                        </div>
                        <div className="gridarea__heading">
                          <h3>
                            <Link to={`course-details/${el.id}`}>
                              {el.name}
                            </Link>
                          </h3>
                        </div>

                        <div className="gridarea__bottom">
                          <div className="gridarea__star">
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <span>{el.rating}</span>
                          </div>
                        </div>
                        <div className="progress mt-3 mb-2">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={el.completion}
                            aria-valuemin="0"
                            aria-valuemax={el.completion}
                            style={{ width: `${el.completion}%` }}
                          >
                            {el.completion}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )
          ) : (
            <h6 className="text-center pt-5">No Coursed registered!</h6>
          )}
        </div>
      </div>
    </div>
  )
}
