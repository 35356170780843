import { Outlet } from "react-router-dom";
import Topbar from "./Topbar";
import Header from "./Header";
import Footer from "./Footer";
import MobileMenu from "./MobileMenu";

export default function Layout() {
  return (
    <main className="main_wrapper overflow-hidden">
      <Topbar />
      <Header />
      <MobileMenu />
      <Outlet />
      <Footer />
    </main>
  );
}
