import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Select from 'react-select'
import axios from 'axios'

import BASE_URL from '../../../config'

function CreateCourses(props) {
  const baseUrl = BASE_URL

  let isError = false
  const [mode, setMode] = useState('create')
  const [activeId, setActiveId] = useState(-1)
  const [courses, setCourses] = useState([])
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [learnings, setLearnings] = useState('')
  const [requirements, setRequirements] = useState('')
  const [languages, setLanguages] = useState([])
  const [duration, setDuration] = useState('')
  const [status, setStatus] = useState('')

  const [instructors, setInstructors] = useState([])
  const [instructor, setInstructor] = useState(null)

  const languageOptions = [
    { value: 'Afrikaans', label: 'Afrikaans' },
    { value: 'Albanian - shqip', label: 'Albanian - shqip' },
    { value: 'Amharic - አማርኛ', label: 'Amharic - አማርኛ' },
    { value: 'Arabic - العربية', label: 'Arabic - العربية' },
    { value: 'Aragonese - aragonés', label: 'Aragonese - aragonés' },
    { value: 'Armenian - հայերեն', label: 'Armenian - հայերեն' },
    { value: 'Asturian - asturianu', label: 'Asturian - asturianu' },
    {
      value: 'Azerbaijani - azərbaycan dili',
      label: 'Azerbaijani - azərbaycan dili',
    },
    { value: 'Basque - euskara', label: 'Basque - euskara' },
    { value: 'Belarusian - беларуская', label: 'Belarusian - беларуская' },
    { value: 'Bengali - বাংলা', label: 'Bengali - বাংলা' },
    { value: 'Bosnian - bosanski', label: 'Bosnian - bosanski' },
    { value: 'Breton - brezhoneg', label: 'Breton - brezhoneg' },
    { value: 'Bulgarian - български', label: 'Bulgarian - български' },
    { value: 'Catalan - català', label: 'Catalan - català' },
    {
      value: 'Central Kurdish - کوردی (دەستنوسی عەرەبی)',
      label: 'Central Kurdish - کوردی (دەستنوسی عەرەبی)',
    },
    { value: 'Chinese - 中文', label: 'Chinese - 中文' },
    {
      value: 'Chinese (Hong Kong) - 中文（香港）',
      label: 'Chinese (Hong Kong) - 中文（香港）',
    },
    {
      value: 'Chinese (Simplified) - 中文（简体）',
      label: 'Chinese (Simplified) - 中文（简体）',
    },
    {
      value: 'Chinese (Traditional) - 中文（繁體）',
      label: 'Chinese (Traditional) - 中文（繁體）',
    },
    { value: 'Corsican', label: 'Corsican' },
    { value: 'Croatian - hrvatski', label: 'Croatian - hrvatski' },
    { value: 'Czech - čeština', label: 'Czech - čeština' },
    { value: 'Danish - dansk', label: 'Danish - dansk' },
    { value: 'Dutch - Nederlands', label: 'Dutch - Nederlands' },
    { value: 'English', label: 'English' },
    { value: 'English (Australia)', label: 'English (Australia)' },
    { value: 'English (Canada)', label: 'English (Canada)' },
    { value: 'English (India)', label: 'English (India)' },
    { value: 'English (New Zealand)', label: 'English (New Zealand)' },
    { value: 'English (South Africa)', label: 'English (South Africa)' },
    { value: 'English (United Kingdom)', label: 'English (United Kingdom)' },
    { value: 'English (United States)', label: 'English (United States)' },
    { value: 'Esperanto - esperanto', label: 'Esperanto - esperanto' },
    { value: 'Estonian - eesti', label: 'Estonian - eesti' },
    { value: 'Faroese - føroyskt', label: 'Faroese - føroyskt' },
    { value: 'Filipino', label: 'Filipino' },
    { value: 'Finnish - suomi', label: 'Finnish - suomi' },
    { value: 'French - français', label: 'French - français' },
    {
      value: 'French (Canada) - français (Canada)',
      label: 'French (Canada) - français (Canada)',
    },
    {
      value: 'French (France) - français (France)',
      label: 'French (France) - français (France)',
    },
    {
      value: 'French (Switzerland) - français (Suisse)',
      label: 'French (Switzerland) - français (Suisse)',
    },
    { value: 'Galician - galego', label: 'Galician - galego' },
    { value: 'Georgian - ქართული', label: 'Georgian - ქართული' },
    { value: 'German - Deutsch', label: 'German - Deutsch' },
    {
      value: 'German (Austria) - Deutsch (Österreich)',
      label: 'German (Austria) - Deutsch (Österreich)',
    },
    {
      value: 'German (Germany) - Deutsch (Deutschland)',
      label: 'German (Germany) - Deutsch (Deutschland)',
    },
    {
      value: 'German (Liechtenstein) - Deutsch (Liechtenstein)',
      label: 'German (Liechtenstein) - Deutsch (Liechtenstein)',
    },
    {
      value: 'German (Switzerland) - Deutsch (Schweiz)',
      label: 'German (Switzerland) - Deutsch (Schweiz)',
    },
    { value: 'Greek - Ελληνικά', label: 'Greek - Ελληνικά' },
    { value: 'Guarani', label: 'Guarani' },
    { value: 'Gujarati - ગુજરાતી', label: 'Gujarati - ગુજરાતી' },
    { value: 'Hausa', label: 'Hausa' },
    { value: 'Hawaiian - ʻŌlelo Hawaiʻi', label: 'Hawaiian - ʻŌlelo Hawaiʻi' },
    { value: 'Hebrew - עברית', label: 'Hebrew - עברית' },
    { value: 'Hindi - हिन्दी', label: 'Hindi - हिन्दी' },
    { value: 'Hungarian - magyar', label: 'Hungarian - magyar' },
    { value: 'Icelandic - íslenska', label: 'Icelandic - íslenska' },
    { value: 'Indonesian - Indonesia', label: 'Indonesian - Indonesia' },
    { value: 'Interlingua', label: 'Interlingua' },
    { value: 'Irish - Gaeilge', label: 'Irish - Gaeilge' },
    { value: 'Italian - italiano', label: 'Italian - italiano' },
    {
      value: 'Italian (Italy) - italiano (Italia)',
      label: 'Italian (Italy) - italiano (Italia)',
    },
    {
      value: 'Italian (Switzerland) - italiano (Svizzera)',
      label: 'Italian (Switzerland) - italiano (Svizzera)',
    },
    { value: 'Japanese - 日本語', label: 'Japanese - 日本語' },
    { value: 'Kannada - ಕನ್ನಡ', label: 'Kannada - ಕನ್ನಡ' },
    { value: 'Kazakh - қазақ тілі', label: 'Kazakh - қазақ тілі' },
    { value: 'Khmer - ខ្មែរ', label: 'Khmer - ខ្មែរ' },
    { value: 'Korean - 한국어', label: 'Korean - 한국어' },
    { value: 'Kurdish - Kurdî', label: 'Kurdish - Kurdî' },
    { value: 'Kyrgyz - кыргызча', label: 'Kyrgyz - кыргызча' },
    { value: 'Lao - ລາວ', label: 'Lao - ລາວ' },
    { value: 'Latin', label: 'Latin' },
    { value: 'Latvian - latviešu', label: 'Latvian - latviešu' },
    { value: 'Lingala - lingála', label: 'Lingala - lingála' },
    { value: 'Lithuanian - lietuvių', label: 'Lithuanian - lietuvių' },
    { value: 'Macedonian - македонски', label: 'Macedonian - македонски' },
    { value: 'Malay - Bahasa Melayu', label: 'Malay - Bahasa Melayu' },
    { value: 'Malayalam - മലയാളം', label: 'Malayalam - മലയാളം' },
    { value: 'Maltese - Malti', label: 'Maltese - Malti' },
    { value: 'Marathi - मराठी', label: 'Marathi - मराठी' },
    { value: 'Mongolian - монгол', label: 'Mongolian - монгол' },
    { value: 'Nepali - नेपाली', label: 'Nepali - नेपाली' },
    { value: 'Norwegian - norsk', label: 'Norwegian - norsk' },
    {
      value: 'Norwegian Bokmål - norsk bokmål',
      label: 'Norwegian Bokmål - norsk bokmål',
    },
    {
      value: 'Norwegian Nynorsk - nynorsk',
      label: 'Norwegian Nynorsk - nynorsk',
    },
    { value: 'Occitan', label: 'Occitan' },
    { value: 'Oriya - ଓଡ଼ିଆ', label: 'Oriya - ଓଡ଼ିଆ' },
    { value: 'Oromo - Oromoo', label: 'Oromo - Oromoo' },
    { value: 'Pashto - پښتو', label: 'Pashto - پښتو' },
    { value: 'Persian - فارسی', label: 'Persian - فارسی' },
    { value: 'Polish - polski', label: 'Polish - polski' },
    { value: 'Portuguese - português', label: 'Portuguese - português' },
    {
      value: 'Portuguese (Brazil) - português (Brasil)',
      label: 'Portuguese (Brazil) - português (Brasil)',
    },
    {
      value: 'Portuguese (Portugal) - português (Portugal)',
      label: 'Portuguese (Portugal) - português (Portugal)',
    },
    { value: 'Punjabi - ਪੰਜਾਬੀ', label: 'Punjabi - ਪੰਜਾਬੀ' },
    { value: 'Quechua', label: 'Quechua' },
    { value: 'Romanian - română', label: 'Romanian - română' },
    {
      value: 'Romanian (Moldova) - română (Moldova)',
      label: 'Romanian (Moldova) - română (Moldova)',
    },
    { value: 'Romansh - rumantsch', label: 'Romansh - rumantsch' },
    { value: 'Russian - русский', label: 'Russian - русский' },
    { value: 'Scottish Gaelic', label: 'Scottish Gaelic' },
    { value: 'Serbian - српски', label: 'Serbian - српски' },
    { value: 'Serbo - Croatian', label: 'Serbo - Croatian' },
    { value: 'Shona - chiShona', label: 'Shona - chiShona' },
    { value: 'Sindhi', label: 'Sindhi' },
    { value: 'Sinhala - සිංහල', label: 'Sinhala - සිංහල' },
    { value: 'Slovak - slovenčina', label: 'Slovak - slovenčina' },
    { value: 'Slovenian - slovenščina', label: 'Slovenian - slovenščina' },
    { value: 'Somali - Soomaali', label: 'Somali - Soomaali' },
    { value: 'Southern Sotho', label: 'Southern Sotho' },
    { value: 'Spanish - español', label: 'Spanish - español' },
    {
      value: 'Spanish (Argentina) - español (Argentina)',
      label: 'Spanish (Argentina) - español (Argentina)',
    },
    {
      value: 'Spanish (Latin America) - español (Latinoamérica)',
      label: 'Spanish (Latin America) - español (Latinoamérica)',
    },
    {
      value: 'Spanish (Mexico) - español (México)',
      label: 'Spanish (Mexico) - español (México)',
    },
    {
      value: 'Spanish (Spain) - español (España)',
      label: 'Spanish (Spain) - español (España)',
    },
    {
      value: 'Spanish (United States) - español (Estados Unidos)',
      label: 'Spanish (United States) - español (Estados Unidos)',
    },
    { value: 'Sundanese', label: 'Sundanese' },
    { value: 'Swahili - Kiswahili', label: 'Swahili - Kiswahili' },
    { value: 'Swedish - svenska', label: 'Swedish - svenska' },
    { value: 'Tajik - тоҷикӣ', label: 'Tajik - тоҷикӣ' },
    { value: 'Tamil - தமிழ்', label: 'Tamil - தமிழ்' },
    { value: 'Tatar', label: 'Tatar' },
    { value: 'Telugu - తెలుగు', label: 'Telugu - తెలుగు' },
    { value: 'Thai - ไทย', label: 'Thai - ไทย' },
    { value: 'Tigrinya - ትግርኛ', label: 'Tigrinya - ትግርኛ' },
    { value: 'Tongan - lea fakatonga', label: 'Tongan - lea fakatonga' },
    { value: 'Turkish - Türkçe', label: 'Turkish - Türkçe' },
    { value: 'Turkmen', label: 'Turkmen' },
    { value: 'Twi', label: 'Twi' },
    { value: 'Ukrainian - українська', label: 'Ukrainian - українська' },
    { value: 'Urdu - اردو', label: 'Urdu - اردو' },
    { value: 'Uyghur', label: 'Uyghur' },
    { value: "Uzbek - o'zbek", label: "Uzbek - o'zbek" },
    { value: 'Vietnamese - Tiếng Việt', label: 'Vietnamese - Tiếng Việt' },
    { value: 'Walloon - wa', label: 'Walloon - wa' },
    { value: 'Welsh - Cymraeg', label: 'Welsh - Cymraeg' },
    { value: 'Western Frisian', label: 'Western Frisian' },
    { value: 'Xhosa', label: 'Xhosa' },
    { value: 'Yiddish', label: 'Yiddish' },
    { value: 'Yoruba - Èdè Yorùbá', label: 'Yoruba - Èdè Yorùbá' },
    { value: 'Zulu - isiZulu', label: 'Zulu - isiZulu' },
  ]

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'pending', label: 'Pending' },
    { value: 'inactive', label: 'Inactive' },
  ]

  useEffect(() => {
    if (!courses.length) {
      getAllCourses()
      getInstructors()
    }
  })

  const getInstructors = () => {
    axios
      .get(baseUrl + '/role-users', {
        ...config,
        params: {
          role: 'instructor',
        },
      })
      .then(response => {
        const insts = []
        response.data.users.forEach(instructor => {
          insts.push({ value: instructor.id, label: instructor.name })
        })
        setInstructors(insts)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const getAllCourses = () => {
    axios
      .get(baseUrl + '/courses')
      .then(res => {
        setCourses(res.data.courses)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleSubmit = e => {
    e.preventDefault()

    let fieldName = []
    if (!instructor) {
      fieldName.push('Instructor')
    }
    if (!name.length) {
      fieldName.push('Name')
    }
    if (!description.length) {
      fieldName.push('Description')
    }
    if (!learnings.length) {
      fieldName.push('Learnings')
    }
    if (!requirements.length) {
      fieldName.push('Requirements')
    }
    if (!languages.length) {
      fieldName.push('Languages')
    }
    if (!duration.length) {
      fieldName.push('Duration')
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + ' is Required!')
      }
      isError = true
    }
    if (isError) {
      return
    }
    mode === 'create' ? createCourse() : updateCourse()
  }

  const clearFields = () => {
    setInstructor('')
    setName('')
    setDescription('')
    setLearnings('')
    setRequirements('')
    setLanguages([])
    setDuration('')
    setStatus('')
    setMode('create')
  }

  const user = JSON.parse(localStorage.getItem('user'))
  const userRole = user.role
  const token = localStorage.getItem('authToken')
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const createCourse = () => {
    const newCourse = {
      instructor_id: instructor,
      name: name,
      description: description,
      learnings: learnings.toString(),
      requirements: requirements,
      languages: languages,
      duration: duration,
      status: status,
    }

    axios
      .post(baseUrl + '/course', newCourse, config)
      .then(res => {
        toast.success(`${res.data.message}`)
        getAllCourses()
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })

    clearFields()
  }

  const editCourse = id => {
    setMode('edit')
    setActiveId(id)
    const course = courses.find(course => course.id === id)
    setInstructor(course.instructor_id)
    setName(course.name)
    setDescription(course.description)
    setLearnings(course.learnings)
    setRequirements(course.requirements)
    setLanguages(course.languages)
    setDuration(course.duration)
    setStatus(course.status)
  }

  const updateCourse = () => {
    axios
      .post(
        baseUrl + '/course/' + activeId,
        {
          instructor_id: instructor,
          name: name,
          description: description,
          learnings: learnings,
          requirements: requirements,
          languages: languages,
          duration: duration,
          status: status,
        },
        config
      )
      .then(res => {
        toast.success(res.data.message)
        getAllCourses()
        clearFields()
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const handleLanguageChange = selectedOptions => {
    const langs = selectedOptions.map(option => option.value)
    setLanguages(langs)
  }

  const handleStatusChange = selectedOption => {
    setStatus(selectedOption.value)
  }

  return (
    <div className="dashboard__content__wraper">
      <h4 className="dashboard__section__title">Course Management</h4>
      <div className="card-body p-2 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Instructor</label>
                <Select
                  options={instructors}
                  value={
                    instructor
                      ? instructors.find(option => option.value === instructor)
                      : ''
                  }
                  onChange={e => setInstructor(e.value)}
                  placeholder="Select Instructor"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Name</label>
                <input
                  type="text"
                  name="course-name"
                  className="form-control"
                  onChange={e => setName(e.target.value)}
                  value={name}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Description
                </label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  onChange={e => setDescription(e.target.value)}
                  value={description}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Learnings</label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  onChange={e => setLearnings(e.target.value)}
                  value={learnings}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Requirements
                </label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  onChange={e => setRequirements(e.target.value)}
                  value={requirements}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Content Languages
                </label>
                <Select
                  isMulti
                  options={languageOptions}
                  value={languages.map(language => ({
                    value: language,
                    label: language,
                  }))}
                  onChange={handleLanguageChange}
                  placeholder="Content Languages"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Duration</label>
                <input
                  type="text"
                  name="course-duration"
                  className="form-control"
                  onChange={e => setDuration(e.target.value)}
                  value={duration}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Status</label>
                <Select
                  options={statusOptions}
                  value={
                    status
                      ? statusOptions.find(option => option.value === status)
                      : ''
                  }
                  onChange={handleStatusChange}
                  placeholder="Course Status"
                />
              </div>
            </div>
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="btn btn-primary"
                type="submit"
                onClick={e => handleSubmit(e)}
              >
                {mode === 'create' ? 'Create' : 'Update'} Course
              </Link>
            </div>
          </div>
        </form>
      </div>
      {courses.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Instructor Name</th>
                <th>Course Name</th>
                <th>Description</th>
                <th>Learnings</th>
                <th>Requirements</th>
                <th>Languages</th>
                <th>Duration</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {courses.map(course =>
                userRole === 'instructor' ? (
                  course.instructor.id === user.id && (
                    <tr key={course.id}>
                      <td>{course.id}</td>
                      <td>{course.instructor.name}</td>
                      <td>{course.name}</td>
                      <td>{course.description}</td>
                      <td>{course.learnings}</td>
                      <td>{course.requirements}</td>
                      <td>{course.languages.join(', ')}</td>
                      <td>{course.duration}</td>
                      <td className="text-capitalize">{course.status}</td>
                      <td className="text-center">
                        <i
                          className="feather-edit text-current text-info"
                          style={{ cursor: 'pointer' }}
                          onClick={() => editCourse(course.id)}
                          onKeyDown={() => {}}
                        >
                          Edit{' '}
                        </i>
                      </td>
                    </tr>
                  )
                ) : (
                  <tr key={course.id}>
                    <td>{course.id}</td>
                    <td>{course.instructor.name}</td>
                    <td>{course.name}</td>
                    <td>{course.description}</td>
                    <td>{course.learnings}</td>
                    <td>{course.requirements}</td>
                    <td>{course.languages.join(', ')}</td>
                    <td>{course.duration}</td>
                    <td className="text-capitalize">{course.status}</td>
                    <td className="text-center">
                      <i
                        className="feather-edit text-current text-info"
                        style={{ cursor: 'pointer' }}
                        onClick={() => editCourse(course.id)}
                        onKeyDown={() => {}}
                      >
                        Edit{' '}
                      </i>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default CreateCourses
