import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

function AssignStudents() {
  const [instructorName, setInstructorName] = useState("");
  const [instructorIds] = useState([
    { value: "I01", label: "I01" },
    { value: "I02", label: "I02" },
    { value: "I03", label: "I03" },
    { value: "I04", label: "I04" },
  ]);
  const [courses] = useState([
    { value: "Operating Systems", label: "Operating Systems" },
    { value: "Networking", label: "Networking" },
    { value: "Algorithms", label: "Algorithms" },
    { value: "Compiler Design", label: "Compiler Design" },
    { value: "System Design", label: "System Design" },
  ]);

  const [students] = useState([
    { value: "Chad Nicolas", label: "Chad Nicolas" },
    { value: "Noah Buckridge", label: "Noah Buckridge" },
    { value: "Sherman Herman", label: "Sherman Herman" },
    { value: "David Durgan", label: "David Durgan" },
    { value: "Hannah Dach", label: "Hannah Dach" },
    { value: "Jesus Hermiston", label: "Jesus Hermiston" },
    { value: "Douglas Hayes PhD", label: "Douglas Hayes PhD" },
    { value: "Merle Gerlach", label: "Merle Gerlach" },
  ]);
  const [selectedInstructorIds, setSelectedInstructorIds] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  let isError = false;
  const [mode, setMode] = useState("assign");
  const [activeId, setActiveId] = useState(-1);
  const [instructors, setInstructors] = useState([]);

  const handleCourseSelection = (selectedValues) => {
    setSelectedCourses(selectedValues);
  };
  const handleIdSelection = (value) => {
    setSelectedInstructorIds(value);
  };

  const handleStudentSelection = (selectedValues) => {
    setSelectedStudents(selectedValues);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!instructorName.length) {
      fieldName.push("Instructor Name");
    }
    if (!selectedInstructorIds) {
      fieldName.push("Instructor ID");
    }
    if (selectedCourses.length === 0) {
      fieldName.push("Selected Courses");
    }
    if (selectedStudents.length === 0) {
      fieldName.push("Selected Students");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "assign") {
      assignStudentCourse();
    } else {
      updateAssignedCourse();
    }
  };
  const clearFields = () => {
    setInstructorName("");
    setSelectedInstructorIds("");
    setSelectedCourses([]);
    setSelectedStudents([]);
    setMode("assign");
  };
  const assignStudentCourse = () => {
    const newInstructor = {
      id: selectedInstructorIds,
      instructorName: instructorName,
      selectedCourses: selectedCourses,
      selectedStudents: selectedStudents,
    };
    setInstructors((prevInstructors) => [...prevInstructors, newInstructor]);
    clearFields();
  };

  const editAssignCourse = (id) => {
    setMode("edit");
    setActiveId(id);
    const instructor = instructors.find((instructor) => instructor.id === id);
    setInstructorName(instructor.instructorName);
    setSelectedInstructorIds(instructor.id);
    setSelectedCourses(instructor.selectedCourses);
    setSelectedStudents(instructor.selectedStudents);
  };

  const deleteAssignedCourse = (id) => {
    const newInstructors = instructors.filter(
      (instructor) => instructor.id !== id
    );
    setInstructors(newInstructors);
    clearFields();
  };

  const updateAssignedCourse = () => {
    const instructor = instructors.find(
      (instructor) => instructor.id === activeId
    );
    instructor.instructorName = instructorName;
    instructor.selectedCourses = selectedCourses;
    instructor.selectedStudents = selectedStudents;
    clearFields();
  };

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Assign Students</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Instructor ID</label>
                  <Select
                    options={instructorIds}
                    value={selectedInstructorIds}
                    onChange={handleIdSelection}
                    placeholder="Select Instructor ID"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Instructor Name</label>
                  <input
                    type="text"
                    name="instructorName"
                    className="form-control"
                    onChange={(e) => setInstructorName(e.target.value)}
                    value={instructorName}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Courses</label>
                  <Select
                    isMulti
                    options={courses}
                    value={selectedCourses}
                    onChange={handleCourseSelection}
                    placeholder="Select Courses"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dashboard__form__wraper">
                <div className="dashboard__form__input">
                  <label>Students</label>
                  <Select
                    isMulti
                    options={students}
                    value={selectedStudents}
                    onChange={handleStudentSelection}
                    placeholder="Select Students"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-12 mt-4">
              <div className="dashboard__form__button pull-right">
                <Link
                  className="default__button"
                  onClick={(e) => handleSubmit(e)}
                >
                  {mode === "assign" ? "Assign" : "Edit"} Students
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {instructors.length > 0 && (
        <div className="row">
          <div className="col-xl-12 mt-5">
            <div className="dashboard__table table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Instructor ID</th>
                    <th>Instructor Name</th>
                    <th>Course(s)</th>
                    <th>Students</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {instructors.map((instructor) => (
                    <tr key={instructor.id?.value}>
                      <td>{instructor.id?.value}</td>
                      <td>{instructor.instructorName}</td>
                      <td>
                        {instructor.selectedCourses
                          .map((course) => course.label)
                          .join(", ")}
                      </td>
                      <td>
                        {instructor.selectedStudents
                          .map((student) => student.label)
                          .join(", ")}
                      </td>
                      <td>
                        <div className="dashboard__button__group">
                          <Link
                            className="dashboard__small__btn"
                            onClick={() => editAssignCourse(instructor.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit"
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                            </svg>{" "}
                            Edit
                          </Link>
                          <Link
                            className="dashboard__small__btn"
                            onClick={() => deleteAssignedCourse(instructor.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6" />
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                              <line x1={10} y1={11} x2={10} y2={17} />
                              <line x1={14} y1={11} x2={14} y2={17} />
                            </svg>{" "}
                            Delete
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AssignStudents;
