import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footerarea">
      <div className="container">
        <div className="footerarea__copyright__wrapper footerarea__copyright__wrapper__2">
          <div className="row">
            <div className="col-xl-3 col-lg-3">
              <div className="copyright__logo">
                <Link to="/">
                  <img
                    src="/img/logo/logo.png"
                    alt="logo"
                    style={{
                      filter: "invert(100%)",
                      mixBlendMode: "screen",
                    }}
                  />
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="footerarea__copyright__content footerarea__copyright__content__2">
                <p>
                  Copyright © <span>{new Date().getFullYear()}</span> UTA. All
                  Rights Reserved.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3">
              <div className="footerarea__icon footerarea__icon__2">
                <ul>
                  <li>
                    <Link>
                      <i className="icofont-facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="icofont-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="icofont-vimeo" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="icofont-linkedin" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="icofont-skype" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
