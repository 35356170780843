import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Select from 'react-select'

import axios from 'axios'
import BASE_URL from '../../../config'

function FacultyDevelopment() {
  const baseUrl = BASE_URL
  const token = localStorage.getItem('authToken')

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  let isError = false

  const [mode, setMode] = useState('add')
  const [fetchFaculties, setFetchFaculties] = useState(false)
  const [faculties, setFaculties] = useState([])
  const [facultyDevelopments, setFacultyDevelopments] = useState([])
  const [facultyDevelopment, setFacultyDevelopment] = useState([])
  const [faculty, setFaculty] = useState('')
  const [skillsToDevelop, setSkillsToDevelop] = useState('')
  const statusOptions = [
    { value: 'todo', label: 'Todo' },
    { value: 'in-progress', label: 'In Progress' },
    { value: 'completed', label: 'Completed' },
    { value: 'failed', label: 'Failed' },
  ]
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (!fetchFaculties) {
      getFaculties()
      getFacultyDevelopments()
    }
  })

  const getFaculties = () => {
    axios
      .get(baseUrl + '/users', config)
      .then(response => {
        const usrs = []
        response.data.users.forEach(user => {
          if (
            user.role === 'instructor' ||
            user.role === 'coordinator' ||
            user.role === 'qa'
          ) {
            usrs.push({ value: user.id, label: user.name })
          }
        })
        setFaculties(usrs)
        setFetchFaculties(true)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const getFacultyDevelopments = () => {
    axios
      .get(baseUrl + '/faculty-developments', config)
      .then(response => {
        setFacultyDevelopments(response.data.faculty)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const handleStatusChange = obj => {
    setStatus(obj)
  }

  const handleSubmit = e => {
    e.preventDefault()
    let fieldName = []
    if (!faculty) {
      fieldName.push('Faculty')
    }
    if (!skillsToDevelop) {
      fieldName.push('Skills to Develop')
    }
    if (!status) {
      fieldName.push('Status')
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + ' is Required!')
      }
      isError = true
    }
    if (isError) {
      return
    }
    if (mode === 'add') {
      addFacultyDevelopment()
    } else {
      updateFacultyDevelopment()
    }
  }

  const addFacultyDevelopment = () => {
    const newFacultyDevelopment = {
      faculty_id: faculty.value,
      skills: skillsToDevelop,
      status: status.value,
    }

    axios
      .post(baseUrl + '/faculty-development', newFacultyDevelopment, config)
      .then(response => {
        toast.success(response.data.message)
        getFacultyDevelopments()
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
    clearFields()
  }

  const clearFields = () => {
    setFaculty('')
    setSkillsToDevelop('')
    setStatus('')
    setMode('add')
  }

  const editFacultyDevelopment = fac => {
    setFacultyDevelopment(fac)
    setFaculty(faculties.find(option => option.value === fac.faculty.id))
    setSkillsToDevelop(fac.skills)
    setStatus(statusOptions.find(option => option.value === fac.status))
    setMode('edit')
  }

  const updateFacultyDevelopment = () => {
    const updatedFacultyDevelopment = {
      faculty_id: faculty.value,
      skills: skillsToDevelop,
      status: status.value,
    }

    axios
      .post(
        baseUrl + '/faculty-development/' + facultyDevelopment.id,
        updatedFacultyDevelopment,
        config
      )
      .then(response => {
        toast.success(response.data.message)
        getFacultyDevelopments()
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
    clearFields()
  }

  return (
    <div className="col-9">
      <div className="dashboard__content__wraper">
        <h4 className="dashboard__section__title">Faculty Development</h4>

        <div className="card-body p-2 w-100 border-0 mb-0">
          <form action="#">
            <div className="row">
              <div className="col-lg-4 mb-3">
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss">Faculty</label>
                  <Select
                    options={faculties}
                    value={faculty}
                    onChange={obj => setFaculty(obj)}
                    placeholder="Select Faculty"
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss">
                    Skills to Develop
                  </label>
                  <input
                    type="text"
                    name="Skills to Develop"
                    className="form-control"
                    value={skillsToDevelop}
                    onChange={e => setSkillsToDevelop(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss">Status</label>
                  <Select
                    isSearchable={false}
                    options={statusOptions}
                    onChange={handleStatusChange}
                    value={status}
                    placeholder="Select Status"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
                <Link className="btn btn-primary" onClick={handleSubmit}>
                  {mode === 'add' ? 'Add' : 'Update'} Faculty Development
                </Link>
              </div>
            </div>
          </form>
        </div>
        {facultyDevelopments?.length > 0 && (
          <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
            <table className="table table-bordered data-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Faculty Name</th>
                  <th>Faculty Role</th>
                  <th>Skills to Develop</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {facultyDevelopments.map(facultyDevelop => (
                  <tr key={facultyDevelop.id}>
                    <td>{facultyDevelop.id}</td>
                    <td>{facultyDevelop.faculty.name}</td>
                    <td className="text-capitalize">
                      {facultyDevelop.faculty.role}
                    </td>
                    <td>{facultyDevelop.skills}</td>
                    <td className="text-capitalize">{facultyDevelop.status}</td>
                    <td className="text-center">
                      <p
                        className="feather-edit text-current"
                        style={{ cursor: 'pointer' }}
                        onClick={() => editFacultyDevelopment(facultyDevelop)}
                      >
                        Edit
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default FacultyDevelopment
