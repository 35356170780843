import axios from 'axios'
import React, { useEffect, useState } from 'react'
import BASE_URL from '../../../config'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

function ExamQuestions() {
  const { examId } = useParams()
  const [questions, setQuestions] = useState([])
  const [isCompleted, setIscompleted] = useState(false)

  const authToken = localStorage.getItem('authToken')
  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }
  console.log('questions', questions)
  const getAllExams = () => {
    axios
      .get(BASE_URL + '/exams', config)
      .then(response => {
        const exams = response.data.exams
        const qtns = exams.find(el => Number(el.id) === Number(examId))
        setQuestions(qtns?.questions)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  useEffect(() => {
    if (!questions.length) {
      getAllExams()
    }
  })

  return (
    <div className="col-xl-9 col-lg-9 col-md-12">
      <div className="dashboard__content__wraper">
        <div className="dashboard__section__title d-flex align-items-center justify-content-between">
          <h6>Please do not close or refresh the page.</h6>
          <h6 className="color-red">Time left: 5:00</h6>
        </div>

        {isCompleted ? (
          <div class="card text-center">
            <div class="card-body" style={{ padding: '8rem 0' }}>
              <h5 class="card-title">Congratulations!</h5>
              <p class="card-text">You have Successfully completed the test</p>
              <button class="btn btn-primary">Your Score : 20/30</button>
            </div>
          </div>
        ) : (
          <div className="row">
            {questions.map((exam, index) => (
              <div className="card mb-4 p-4">
                <span className="mb-4  text-primary">Question {index + 1}</span>
                <h5>{exam.question}</h5>
                <span className="mb-2">Options</span>
                {exam.options.map(option => (
                  <p
                    className="card bg-light p-3 "
                    style={{ cursor: 'pointer' }}
                  >
                    {' '}
                    {option}
                  </p>
                ))}
              </div>
            ))}
            <div>
              <button
                onClick={() => setIscompleted(true)}
                className="btn btn-primary"
              >
                Submit Test
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ExamQuestions
