import { Link } from 'react-router-dom'
import BASE_URL from '../../../config'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

function AdminActions() {
  const baseUrl = BASE_URL
  const token = localStorage.getItem('authToken')

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const [analytics, setAnalytics] = useState(null)

  useEffect(() => {
    if (!analytics) {
      getAnalytics()
    }
  })

  const getAnalytics = () => {
    axios
      .get(baseUrl + '/analytics', config)
      .then(response => {
        setAnalytics(response.data)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  return (
    <div className="dashboard__content__wraper">
      <div className="dashboard__section__title">
        <h4>Dashboard</h4>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-12 col-12">
          <div className="dashboard__single__counter">
            <div className="counterarea__text__wraper">
              <div className="counter__img">
                <img src="../img/counter/counter__3.png" alt="counter" />
              </div>
              <div className="counter__content__wraper">
                <div className="counter__number">
                  <span>{analytics?.users}</span>
                </div>
                <p>Total Students</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12 col-12">
          <div className="dashboard__single__counter">
            <div className="counterarea__text__wraper">
              <div className="counter__img">
                <img src="../img/counter/counter__1.png" alt="counter" />
              </div>
              <div className="counter__content__wraper">
                <div className="counter__number">
                  <span>{analytics?.courses}</span>
                </div>
                <p>Courses</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12 col-12">
          <div className="dashboard__single__counter">
            <div className="counterarea__text__wraper">
              <div className="counter__img">
                <img src="../img/counter/counter__2.png" alt="counter" />
              </div>
              <div className="counter__content__wraper">
                <div className="counter__number">
                  <span>{analytics?.exams}</span>
                </div>
                <p>Exams</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-12 col-12">
          <div className="dashboard__single__counter">
            <div className="counterarea__text__wraper">
              <div className="counter__img">
                <img src="../img/counter/counter__4.png" alt="counter" />
              </div>
              <div className="counter__content__wraper">
                <div className="counter__number">
                  <span>{analytics?.classes}</span>
                </div>
                <p>Classes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__section__title">
              <h4>Students</h4>
            </div>
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/manage-students">
                      Manage Students
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/manage-courses">
                      Manage Courses
                    </Link>
                  </h4>
                </div>
              </div>

              {/* <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/reports-and-performance">
                      Reports & Performance
                    </Link>
                  </h4>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__section__title">
              <h4>QA Officers</h4>
            </div>
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/manage-qa-officers">
                      Manage QA Officers
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/assign-course">
                      Assign Course
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/assign-support-tickets">
                      Support & Enquiries
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/improvement-initiatives">
                      Improvement Initiatives
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__section__title">
              <h4>Instructors</h4>
            </div>
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/manage-instructors">
                      Manage Instructors
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/handle-courses">
                      Handle Courses
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/course-content">
                      Manage Course Content
                    </Link>
                  </h4>
                </div>
              </div>

              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/handle-exams">Handle Exams</Link>
                  </h4>
                </div>
              </div>

              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/course-materials">
                      Manage Course materials
                    </Link>
                  </h4>
                </div>
              </div>

              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/manage-assignments">
                      Manage Assignments
                    </Link>
                  </h4>
                </div>
              </div>

              {/* <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/reviews">Reviews</Link>
                  </h4>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="dashboard__content__wraper admin__content__wrapper">
            <div className="dashboard__section__title">
              <h4>Coordinator</h4>
            </div>
            <div className="dashboard__popular__instructor">
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/manage-coordinators">
                      Manage Coordinators
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/handle-programs">
                      Handle Programs
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/assign-support-tickets">
                      Assign Support Tickets
                    </Link>
                  </h4>
                </div>
              </div>
              {/* <div className="dashboard__popular__instructor__single">
                <div className="dashboard__popular__instructor__content">
                  <h4>
                    <Link to="/dashboard/admin/analytics">Analytics</Link>
                  </h4>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminActions
